import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';

import { frameFilterArray } from '../components/Utils';
import SearchTable from '../components/Table/SearchTable';
// Utils
import { getUsers } from '../STORE/MiddleWare/Users';
import { UseUsers } from '../STORE/Selector/Users';

import DrawerUsers from '../sections/users/DrawerUsers';
import UpdatePasswordDrawer from '../sections/users/UpdatePasswordDrawer';
// CSS
import '../css/Users.scss';

const Users = () => {
  const dispatch = useDispatch();
  // State
  const [usersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState('new');
  const [rowValue, setRowValue] = useState({});
  const [userId, setUserId] = useState('');

  // Custom Selector Hooks
  const users = UseUsers();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setUsersData(users);
  }, [users]);

  const onRowClickHandler = (e, value) => {
    if (e.target.classList.contains('change-password')) return;
    setShow(true);
    setUserType('existing');
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setShowPassword(false);
    setUserType('new');
    setRowValue({});
  };

  const UpdatePasswordHandler = (id) => {
    setShow(false);
    setShowPassword(true);
    setUserId(id);
  };

  const addNewUser = {
    modalId: 'showModal',
    label: 'New User',
    onClick: onNewClickHandler,
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '50px',
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '250px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(usersData, "name"),
      onFilter: (value, record) => record.name === value,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      align: 'center',
      width: '200px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(usersData, "designation"),
      onFilter: (value, record) => record.designation === value,
      sorter: (a, b) => a.designation.localeCompare(b.designation),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      width: '200px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(usersData, "email"),
      onFilter: (value, record) => record.email === value,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return (
          <div>
            <span className={text === 'Active' ? 'status-active' : 'status-inactive'}>{text}</span>
          </div>
        );
      },
      filters: frameFilterArray(usersData, "status"),
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Update Password',
      dataIndex: 'password',
      key: 'password',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: '  change-password' }),
      render(text, row) {
        return (
          <Button
            type="primary"
            className="change-password-button change-password"
            onClick={() => UpdatePasswordHandler(row._id)}
          >
            <img src="assets/icons/ic_change_password.svg" className="change-password" alt="Change Password" />
          </Button>
        );
      },
    },
  ];


  return (
    <>
      <Helmet>
        <title> User's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4">
        <SearchTable
          columns={columns}
          data={usersData}
          searchItems={['name', 'employeeId', 'email', 'mobile', 'designation']}
          addNewPopup={addNewUser}
          onRowClickHandler={onRowClickHandler}
          onRowChangeHandler={() => { }}
        />
      </div>

      <DrawerUsers show={show} setShow={setShow} userType={userType} rowValue={rowValue} />
      <UpdatePasswordDrawer show={showPassword} setShow={setShowPassword} userId={userId} />
    </>
  );
};

export default Users;
