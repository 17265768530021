import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// Utils
import { getClientsBilling } from '../../../STORE/MiddleWare/Clients';
import { UseClientBillings } from '../../../STORE/Selector/Clients';

// Component
import { frameFilterArray } from '../../../components/Utils';
import TableContainer from '../../../components/Table/Table';

const FeeSummary = ({ clientId, open }) => {
  const dispatch = useDispatch();
  // State
  const [billingData, setBillingData] = useState([]);

  // Custom Selector Hooks
  const billings = UseClientBillings();

  useEffect(() => {
    if (clientId && open)
      dispatch(getClientsBilling({ clientId }));;
  }, [clientId, open]);

  useEffect(() => {
    setBillingData(billings?.billing);
  }, [billings]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: '' }),
      render(text, record) {
        return <div>{text ? moment(record.date).format('YYYY-MM-DD') : null}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'Period',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: 'text-left ' }),
      render(text) {
        return <div>{text}</div>;
      },
      fixed: 'left',
      filters: frameFilterArray(billingData, 'period'),
      onFilter: (value, record) => record.period === value,
      sorter: (a, b) => a.period.localeCompare(b.period),
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Particulars',
      dataIndex: 'particulars',
      key: 'particulars',
      align: 'center',
      width: '120px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Bill Raised',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Debit' ? record.amount : 0}</div>;
      },
    },
    {
      title: 'Bill Collected',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Credit' ? record.amount : 0}</div>;
      },
    },
    {
      title: 'Mode of Payment',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(billingData, 'modeOfPayment'),
      onFilter: (value, record) => record.modeOfPayment === value,
    },
  ];

  return (
    <div className="row client-wrapper">
      <TableContainer columns={columns} data={billingData} />
    </div>
  );
};

export default FeeSummary;
