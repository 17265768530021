import moment from 'moment';
/* eslint-disable react/prop-types */
import { Button } from 'antd';
import InputContainer from '../../components/InputContainer/InputContainer';

const Bills = ({ bills, setBills, mode, currentStatus, billType, isSuperAdmin }) => {

  const BillDateChangeHandler = (index, e) => {
    const { name, value } = e.target;

    const existingBill = [...bills];

    existingBill[index][name] = value;
    setBills([...existingBill]);
  };

  const BillChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const existingBill = [...bills];
    existingBill[index][name] = value;
    setBills([...existingBill]);
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject, index) => {
    const existingBill = [...bills];
    existingBill[index][nameField] = valueObject[valueField];
    setBills([...existingBill]);
  };

  const newBillHandler = () => {
    const newBill = {
      billNo: '',
      billDate: '',
      gstIn: '',
      partyName: '',
      billValue: '',
      billStatus: '',
    };
    setBills([...bills, newBill]);
  };

  const deleteBillHandler = (index) => {
    setBills([...bills.toSpliced(index, 1)]);
  };

  const handleToggleChangeHandler = (name, value, index) => {
    const existingBill = [...bills];
    existingBill[index][name] = value;
    setBills([...existingBill]);
  };

  return (
    <>
      {bills?.map((item, index) => (
        <>
          <div className="d-flex flex-wrap" key={index}>
            <InputContainer
              containerClassName="pe-3 col-md-2"
              label={index === 0 ? "Bill No" : ""}
              type="text"
              id={`billNo-${index + 1}`}
              name="billNo"
              value={item.billNo}
              onChange={(e) => BillChangeHandler(e, index)}
              placeholder="Please Enter Bill No"
              disabled={currentStatus !== 3 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 w-150-px"
              label={index === 0 ? "Bill Date" : ""}
              type="prime-date"
              id={`billDate-${index + 1}`}
              name="billDate"
              format="dd/mm/yy"
              value={item.billDate}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e) => BillDateChangeHandler(index, e)}
              placeholder="Please Choose Bill Date"
              disabled={currentStatus !== 3 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 w-200-px"
              label={index === 0 ? "GSTIN" : ""}
              type="text"
              id={`gstIn-${index + 1}`}
              name="gstIn"
              value={item.gstIn}
              onChange={(e) => BillChangeHandler(e, index)}
              placeholder="Please Enter GSTIN"
              disabled={currentStatus !== 3 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 col-md-2"
              label={index === 0 ? "Party Name" : ""}
              type="text"
              id={`partyName-${index + 1}`}
              name="partyName"
              value={item.partyName}
              onChange={(e) => BillChangeHandler(e, index)}
              placeholder="Please Enter Party Name"
              disabled={currentStatus !== 3 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 col-md-2"
              label={index === 0 ? "Bill Value" : ""}
              type="number"
              id={`billValue-${index + 1}`}
              name="billValue"
              value={item.billValue}
              onChange={(e) => BillChangeHandler(e, index)}
              placeholder="Please Enter Bill Value"
              disabled={currentStatus !== 3 && !isSuperAdmin}
            />
            {billType === "Creditor filed, bill missing" ?
              <InputContainer
                containerClassName="pe-3 w-150-px"
                label={index === 0 ? "Bill Status" : ""}
                type="select"
                id={`billStatus-${index + 1}`}
                name="billStatus"
                value={item.billStatus}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(value) => handleSelectChangeHandler('billStatus', 'value', value, index)}
                options={billStatusOptions}
                optionsValueKey="value"
                optionsLabelKey="label"
                placeholder="Bill Status"
                disabled={currentStatus !== 3 && !isSuperAdmin}
              /> :
              <InputContainer
                containerClassName="pe-3 col-md-1"
                label={index === 0 ? "Filed" : ""}
                type="toggle"
                checkedChildren="Yes"
                unCheckedChildren="No"
                id={`filed-${index + 1}`}
                name="filed"
                value={item?.filed}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(value) => handleToggleChangeHandler('filed', value, index)}
                disabled={currentStatus !== 3 && !isSuperAdmin}
              />
            }
            {currentStatus === 3 || isSuperAdmin ?
              <Button type="primary" danger className="mt-2 align-self-end" onClick={() => deleteBillHandler(index)}>
                Remove
              </Button>
              : <></>}
          </div>
          <hr />
        </>
      ))}
      {currentStatus === 3 || isSuperAdmin ?
        <Button type="primary" className="my-2" onClick={newBillHandler}>
          Add New Bill
        </Button>
        : <></>}

    </>
  );
};

export default Bills;

const billStatusOptions = [
  { label: 'Collected', value: 'Collected' },
  { label: 'Ignored', value: 'Ignored' },
]