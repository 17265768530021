/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { difference } from '../../components/Utils';

import { getAllLogs } from '../../STORE/MiddleWare/Utils';
import { UseLogs } from '../../STORE/Selector/Utils';

// eslint-disable-next-line react/prop-types
const DrawerGstLog = ({ show, setShow, id }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const LOGS = UseLogs();
  useEffect(() => {
    setData(LOGS);
  }, [LOGS]);

  useEffect(() => {
    if (show && id) {
      dispatch(getAllLogs({
        pageItemId: id,
        page: "GstTasks"
      })).then(() => {
        setOpen(show);
      });
    }
  }, [show, id])

  const onClose = () => {
    setOpen(false);
    setShow(false);
  };

  const differences = []
  data?.forEach(element => {
    const dif = difference(element?.newvalue, element?.previousvalue);
    differences.push(dif);
  });

  return (
    <Drawer
      title='Log'
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <div>
        <LogGenerator differences={differences} />
      </div>
    </Drawer>
  );
};

export default DrawerGstLog;


const LogGenerator = ({ differences }) =>
  <div className='log-wrapper'>
    {differences?.map(diff => (
      // eslint-disable-next-line react/jsx-key
      <div className='log-container' key={diff._id}>
        {Object.entries(diff).map(([key, value]) => (<div key={key} className='log-item'>
          <strong>{gstTasksLabels[key]} : </strong>
          {(key === "updatedAt" || key === "dataCollectedDate" || key === "tallyEntryDate" || key === "gstr1FilingDate" || key === "collectionDate" || key === "gstr3FiledDate" || key === "downloadDate") ? moment(value).format('YYYY-MM-DD hh:mm A') :
            (key === "currentUser") ? value.name :
              (key === "nilReturn") ? (value ? "Yes" : "No") :
                (key === "gstr2bReconciliation") ? gstTasksLabels[value] :
                  (key === "step") ? steps[value] :
                    (typeof value === "object") ? <div>{ObjectToTable(value)}</div> : value}
        </div>
        )
        )}
      </div>
    ))}
  </div>

const ObjectToTable = (arr) => (
  <table className='log-table'>
    {
      arr.map((item, index) => (
        <>
          {index === 0 ? <tr>
            {Object.entries(item).map(([key]) => <th key={key} className='log-item'>{gstTasksLabels[key]}</th>)}
          </tr>
            : <></>}
          <tr key={index}>
            {Object.entries(item).map(([key, value]) => <td key={key} className='log-item'>{value}</td>)}
          </tr>
        </>
      ))}
  </table>
)
const gstTasksLabels = {
  "feedback": "Feedback",
  "nilReturn": "Nil Return",
  "dataCollectedDate": "Data Collected Date",
  "tallyEntryDate": "Tally Entry Date",
  "acknowledgementNo": "Acknowledgement Number",
  "gstr1FilingDate": "GSTR-1 Filing Date",
  "gstr2bReconciliation": "GSTR-2B Reconciliation",
  "gstr2bCounterPartyBills": "GSTR-2B Creditor Not Filed",
  "gstr2bMissingBills": "GSTR-2B Creditor Filed, Bill Missing",
  "gstPayable": "GST Payable",
  "taxValue": "Tax Value",
  "fee": "Fee",
  "penalty": "Penalty",
  "interest": "Interest",
  "totalGstPayment": "Total GST Payment",
  "taxCollected": "Tax Collected",
  "feeCollected": "Fee Collected",
  "collectionDate": "Collection Date",
  "modeOfPayment": "Mode of Payment",
  "gstr3AcknowledgementNo": "Acknowledgement Number",
  "gstr3FiledDate": "Filed Date",
  "downloadDate": "Download Date",
  "step": "Status",
  "billNo": "Bill No",
  "billDate": "Bill Date",
  "gstIn": "GSTIN",
  "partyName": "Party Name",
  "billValue": "Bill Value",
  "billStatus": "Bill Status",
  "filed": "Filed",
  "notes": "Notes",
  "reminderDate": "Reminder Date",
  "updatedAt": "Updated At",
  "currentUser": "Updated By",
  'DataMatching': 'Data is Matching',
  'DataNotMatching': 'Data is Not Matching',
}
const steps = [
  'Contact the Client',
  'Tally Entry',
  'GSTR-1 Filing',
  'GSTR-2B Reconciliation',
  'GSTR-3B Upload',
  'Inform the Payment',
  'Collection of the Payment',
  'GSTR-3B Filing',
  'Challan Download',
  'Task Completed'
];
