import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All GstTasks
export const getGstTasks = createAsyncThunk('gstTask/getGstTasks', async (arg) => {
  try {
    const response = await axios.get(`${urls.gstTaskUrl.url}?month=${arg.month}&year=${arg.year}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All GstTask
export const getGstTask = createAsyncThunk('gstTask/getGstTask', async () => {
  try {
    const response = await axios.get(`${urls.gstTaskUrl.url}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Create GstTask
export const createGstTask = createAsyncThunk('gstTask/createGstTask', async (arg) => {
  try {
    const response = await axios.post(`${urls.gstTaskUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update GstTask
export const updateGstTask = createAsyncThunk('gstTask/updateGstTask', async (arg) => {
  try {
    const response = await axios.patch(`${urls.gstTaskUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Delete GstTask
export const deleteGstTask = createAsyncThunk('gstTask/deleteGstTask', async (arg) => {
  try {
    const response = await axios.delete(`${urls.gstTaskUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});
