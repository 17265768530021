import { createSlice } from '@reduxjs/toolkit';
import { getItTasks, getItTask, createItTask } from '../MiddleWare/ItTask';

const initialState = {
  itTaskData: [],
  month: '',
  year: null,
  itTask: null,
  error: '',
  loading: false,
};

const itTaskSlice = createSlice({
  name: 'itTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getItTasks.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getItTasks.fulfilled, (state, action) => {
      if (action.payload) {
        state.itTaskData = action.payload;
      }
      state.loading = false;
    });

    builder.addCase(getItTasks.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = false;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getItTask.pending, () => {});

    builder.addCase(getItTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.itTask = action.payload.itTask;
      }
    });

    builder.addCase(getItTask.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createItTask.pending, () => {});

    builder.addCase(createItTask.fulfilled, (state, action) => {
      if (action.payload) {
        state.itTask = action.payload.itTask;
      }
    });

    builder.addCase(createItTask.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export default itTaskSlice.reducer;
