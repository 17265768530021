import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Helmet } from 'react-helmet-async';
import moment from "moment";
import _ from "lodash";

// @mui
import { Grid, Container } from '@mui/material';

// components
import { Col, Row } from "antd";

// sections
import { AppWidgetSummary } from '../../@dashboard/app';
import InputContainer from '../../../components/InputContainer/InputContainer';
import { formatDatetoDb } from '../../../components/Utils';
import SearchTable from '../../../components/Table/SearchTable';

// Utils
import { getClientsAutoComplete } from '../../../STORE/MiddleWare/AutoComplete';
import { UseAutoCompleteClients } from '../../../STORE/Selector/AutoComplete';
import { UseBillingForTaxDashboard, UseBillingTaxSearchValue } from "../../../STORE/Selector/Billing"
import { getBillingForTaxDashboard } from '../../../STORE/MiddleWare/Billing';
import { updateTaxSearchValue } from "../../../STORE/Slice/Billing";
// ----------------------------------------------------------------------

// -----------------

const TaxDashboard = () => {
  const dispatch = useDispatch();

  const [clientsData, setClientsData] = useState([]);
  const [data, setData] = useState({
    clientId: '',
    fromDate: '',
    toDate: ''
  })
  const [taxData, setTaxData] = useState([])

  const clients = UseAutoCompleteClients();
  const taxDashboard = UseBillingForTaxDashboard();
  const taxSearchValue = UseBillingTaxSearchValue();

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {
    dispatch(updateTaxSearchValue({ ...data, [nameField]: valueObject[valueField] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateTaxSearchValue({ ...data, [name]: value }));
  };

  useEffect(() => {
    setData({ ...taxSearchValue });

    if (taxSearchValue.clientId) {
      const payload = {
        clientId: taxSearchValue.clientId,
        fromDate: formatDatetoDb(taxSearchValue.fromDate),
        toDate: formatDatetoDb(taxSearchValue.toDate)
      }

      dispatch(getBillingForTaxDashboard(payload));
    };

  }, [taxSearchValue, dispatch]);

  useEffect(() => {
    dispatch(getClientsAutoComplete());
  }, []);



  useEffect(() => {
    const updatedTaxDashboard = taxDashboard?.map((item) => ({
      ...item,
      taxValue: item?.taxValue ? Number(item?.taxValue) : 0,
      fee: item?.fee ? Number(item?.fee) : 0,
      penalty: item?.penalty ? Number(item?.penalty) : 0,
      interest: item?.interest ? Number(item?.interest) : 0,
      taxCollected: item?.taxCollected ? Number(item?.taxCollected) : 0,
      feeCollected: item?.feeCollected ? Number(item?.feeCollected) : 0,
    }))
    setTaxData(updatedTaxDashboard);
  }, [taxDashboard]);

  useEffect(() => {
    if (clients) {
      const data = [];
      clients?.forEach((element) => {
        data.push({
          label: element?.clientName,
          value: element?._id,
        });
      });
      setClientsData(data);
    }
  }, [clients]);


  const columns = [
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'Period',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: 'text-left ' }),
      render(text, record) {
        return <div>{moment(`${record?.month}-${record?.year}`, 'MM-YYYY').format('MMMM-YYYY')}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Tax Value',
      dataIndex: 'taxValue',
      key: 'taxValue',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      key: 'penalty',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Total GST',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{(Number(record?.gstPayable) || 0) +
          (Number(record?.penalty) || 0) +
          (Number(record?.interest) || 0) +
          (Number(record?.fee) || 0)}</div>;
      },
    },
    {
      title: 'Tax Collected',
      dataIndex: 'taxCollected',
      key: 'taxCollected',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Fee Collected',
      dataIndex: 'feeCollected',
      key: 'feeCollected',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Collection Date',
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text ? moment(text).utc().format('DD/MM/YYYY') : null}</div>;
      },
    },
    {
      title: 'Mode of Payment',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
  ];


  const totalTaxValue = _.sumBy(taxData, 'taxValue') || 0;
  const totalFee = _.sumBy(taxData, 'fee') || 0;
  const totalPenalty = _.sumBy(taxData, 'penalty') || 0;
  const totalInterest = _.sumBy(taxData, 'interest') || 0;
  const totalTaxCollected = _.sumBy(taxData, 'taxCollected') || 0;
  const totalFeeCollected = _.sumBy(taxData, 'feeCollected') || 0;
  const totalTotalGST = (Number(totalTaxValue) + Number(totalFee) + Number(totalPenalty) + Number(totalInterest)) || 0;

  return (
    <>
      <Helmet>
        <title> Dashboard | Gems Clip </title>
      </Helmet>

      <Container maxWidth="xl">
        <div className="mb-4 mt-4">
          <Row gutter={20}>
            {/* Client List as select (single select) (Through getallClientAutocomplete), From date, To Date Goes here */}
            <Col span={6}>
              <InputContainer
                // containerClassName="col-md-4"
                label=""
                type="select"
                id="clientId"
                name="clientId"
                placeholder="Please select Client"
                value={data?.clientId}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleSelectChangeHandler.bind(this, 'clientId', 'value')}
                options={clientsData}
                optionsValueKey="value"
                optionsLabelKey="label"
              />
            </Col>
            <Col span={4}>
              <InputContainer
                label=""
                type="prime-date"
                id="fromDate"
                name="fromDate"
                value={data?.fromDate}
                onChange={handleChange}
                placeholder="Please Select Drom date"
                picker="date"
                format="dd/mm/yy"
                showButtonBar
              />
            </Col>
            <Col span={4}>
              <InputContainer
                label=""
                type="prime-date"
                id="toDate"
                name="toDate"
                value={data?.toDate}
                onChange={handleChange}
                placeholder="Please Select To Date"
                picker="date"
                format="dd/mm/yy"
                showButtonBar
              />
            </Col>
          </Row>
        </div>
        <Grid container className="tax-card-container">
          <div className="tax-card">
            <AppWidgetSummary title="Tax Value" total={totalTaxValue} color="info" />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Fee" total={totalFee} />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Penalty" total={totalPenalty} color="info" />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Interest" total={totalInterest} />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Total GST" total={totalTotalGST} color="info" />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Tax Collected" total={totalTaxCollected} />
          </div>

          <div className="tax-card">
            <AppWidgetSummary title="Fee Collected" total={totalFeeCollected} color="info" />
          </div>
        </Grid>
        <span>
          <div className="row mt-4 client-wrapper">
            {data?.clientId ?
              <SearchTable
                columns={columns}
                data={taxData}
              />
              : <></>}
          </div>
        </span>

      </Container>
    </>
  );
}


export default TaxDashboard;