import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get ItDueDate
export const getItDueDate = createAsyncThunk('itDueDate/getItDueDate', async () => {
    try {
        const response = await axios.get(`${urls.itDueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Get All ItDueDate
export const getAllItDueDates = createAsyncThunk('itDueDate/getAllItDueDates', async () => {
    try {
        const response = await axios.get(`${urls.itDueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Create ItDueDate
export const createItDueDate = createAsyncThunk('itDueDate/createItDueDate', async (arg,) => {
    try {
        const response = await axios.post(`${urls.itDueDatesUrl.url}`, arg);
        return response.data
    } catch (err) {
        return err
    }
})

// Update ItDueDate
export const updateItDueDate = createAsyncThunk('itDueDate/updateItDueDate', async (arg,) => {
    try {
        const response = await axios.patch(`${urls.itDueDatesUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Update ItDueDate History
export const updateItDueDateHistory = createAsyncThunk('itDueDate/updateItDueDateHistory', async (arg,) => {
    try {
        const response = await axios.patch(`${urls.itDueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Delete ItDueDate
export const deleteItDueDate = createAsyncThunk('itDueDate/deleteItDueDate', async (arg,) => {
    try {
        const response = await axios.delete(`${urls.itDueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})