import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get All Logs
export const getAllLogs = createAsyncThunk('utils/getAllLogs', async (arg) => {
  try {
    const response = await axios.get(`${urls.logUrl.url}/${arg.page}/${arg.pageItemId}`)
    return response.data
  } catch (err) {
    return err
  }
})