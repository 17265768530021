import React, { useState } from "react";
import { Table, Empty } from "antd";
import "./Table.scss";

// eslint-disable-next-line react/prop-types
const TableContainer = ({ columns, data, scroll, pagination, onClick, rowClassName, inline, onChange }) => {
  const [page, setPage] = useState(1);

  const tableColumns = [];

  // eslint-disable-next-line no-unused-expressions, react/prop-types
  columns?.map((column) => tableColumns.push({ ...column, className: `${column?.className || ""} ${inline ? "p-5" : "py-10-px px-10-px"}` }))

  const tableData = [];

  // eslint-disable-next-line no-unused-expressions, react/prop-types
  data?.length > 0 && data?.map((info, index) => tableData.push({ ...info, page, tableKey: index }))

  return (
    <div className={`${inline ? "custom-inline-table-container" : "custom-table-container"}`}>
      <Table
        rowKey="tableKey"
        locale={{ emptyText: inline ? <></> : <Empty description="No Data Found" className="empty-margin" /> }}
        className="custom-table"
        columns={tableColumns}
        dataSource={tableData}
        bordered
        pagination={pagination ? {
          onChange(current) {
            setPage(current);
          }
        } : false}
        scroll={scroll}
        onRow={(record, rowIndex) => ({
          // eslint-disable-next-line no-unused-expressions
          onClick: event => { onClick?.(event, record) }, // click row
          onDoubleClick: event => { }, // double click row
          onContextMenu: event => { }, // right button click row
          onMouseEnter: event => { }, // mouse enter row
          onMouseLeave: event => { }, // mouse leave row
        })}
        onChange={onChange}
        rowClassName={rowClassName}
      />
    </div>
  );
};


export default TableContainer;
