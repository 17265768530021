import { createSlice } from '@reduxjs/toolkit';
import { getClients, getClient, createClient, getClientsBilling, getClientsGst, } from '../MiddleWare/Clients';

const initialState = {
  clientsData: [],
  billingsData: [],
  gstData: [],
  month: '',
  year: null,
  client: null,
  error: '',
  loading: false,
};

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClients.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getClients.fulfilled, (state, action) => {
      if (action.payload) {
        state.clientsData = action.payload;
      }
      state.billingsData = [];
      state.gstData = [];
      state.loading = false;
    });

    builder.addCase(getClients.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = false;
      }
      state.clientsData = [];
      state.billingsData = [];
      state.gstData = [];
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClient.pending, () => { });

    builder.addCase(getClient.fulfilled, (state, action) => {
      if (action.payload) {
        state.client = action.payload.client;
        state.billingsData = [];
        state.gstData = [];
      } else {
        state.client = {}
        state.billingsData = [];
        state.gstData = [];
      }
    });

    builder.addCase(getClient.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
      state.client = {}
      state.billingsData = [];
      state.gstData = [];
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createClient.pending, () => { });

    builder.addCase(createClient.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.client;
      }
    });

    builder.addCase(createClient.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClientsBilling.pending, () => { });

    builder.addCase(getClientsBilling.fulfilled, (state, action) => {
      if (action.payload) {
        state.billingsData = action.payload;
      } else {
        state.billingsData = [];
        state.gstData = [];
      }
    });

    builder.addCase(getClientsBilling.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
      state.billingsData = [];
      state.gstData = [];
    });


    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getClientsGst.pending, () => { });

    builder.addCase(getClientsGst.fulfilled, (state, action) => {
      if (action.payload) {
        state.gstData = action.payload;
      } else {
        state.billingsData = [];
        state.gstData = [];
      }
    });

    builder.addCase(getClientsGst.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
      state.billingsData = [];
      state.gstData = [];
    });
  },
});

export default clientSlice.reducer;
