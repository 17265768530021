import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

// Component
import { frameFilterArray } from '../components/Utils';
import SearchTable from '../components/Table/SearchTable';
import DrawerGstTask from '../sections/gstTask/DrawerGstTask';
import DrawerClients from '../sections/clients/DrawerClients';
import DrawerGstLog from '../sections/log/DrawerGstLog';
// Utils
import { UseGstTasks } from '../STORE/Selector/GstTask';
import { getGstTasks } from '../STORE/MiddleWare/GstTask';
import { UseViewMonth } from '../STORE/Selector/Utils';

// CSS
import '../css/Clients.scss';

const GstTask = () => {
  const dispatch = useDispatch();

  // State
  const [show, setShow] = useState(false);
  const [recordType, setRecordType] = useState('new');
  const [clientsShow, setClientsShow] = useState(false);
  const [logsShow, setLogsShow] = useState(false);
  const [rowValue, setRowValue] = useState({});
  const [logValue, setLogValue] = useState(null);
  const [gstTaskData, setGstTaskData] = useState([]);

  // Custom Selector Hooks
  const gstTasks = UseGstTasks();

  const CurrentViewMonth = UseViewMonth();

  useEffect(() => {
    if (CurrentViewMonth?.month && CurrentViewMonth?.year) {
      dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
    }
  }, [CurrentViewMonth?.month, CurrentViewMonth?.year]);

  useEffect(() => {
    setGstTaskData(gstTasks?.GstTask);
  }, [gstTasks]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setRecordType('existing');
    setRowValue(value);
  };

  const onClientClickHandler = (id) => {
    setShow(false);
    setClientsShow(true);
    setLogsShow(false);
    setRowValue({
      _id: id,
    });
  };

  const OpenChangelogHandler = (id) => {
    setShow(false);
    setLogsShow(true);
    setClientsShow(false);
    setLogValue(id)
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '40px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
      fixed: 'left',
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '50px',
      onCell: () => ({ className: '' }),
      render(text, row) {
        return <div>{row?.client?.clientId}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '150px',
      onCell: () => ({
        onClick: event => { event.stopPropagation(); },
        className: 'ellipsis-1'
      }),
      render(text, row) {
        return (
          <Button
            type="link"
            className="client-info-button client-info"
            onClick={() => onClientClickHandler(row.clientId)}
          >
            <p className="client-info">{text}</p>
          </Button>
        );
      },
      fixed: 'left',
      filters: frameFilterArray(gstTaskData, 'clientName'),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Client Type',
      dataIndex: 'clientType',
      key: 'clientType',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: ' ' }),
      render(text, row) {
        return <div>{row?.client?.clientType}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'step',
      key: 'step',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{steps[text]}</div>;
      },
      filters: stepsFilter,
      onFilter: (value, record) => record.step === value,
      sorter: (a, b) => a.step - b.step,
    },
    {
      title: 'GST Type',
      dataIndex: 'gstType',
      key: 'gstType',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(gstTaskData, 'gstType'),
      onFilter: (value, record) => record.gstType === value,
    },
    {
      title: 'Tax Value',
      dataIndex: 'taxValue',
      key: 'taxValue',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(gstTaskData, 'taxValue'),
      onFilter: (value, record) => record.taxValue === value,

    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(gstTaskData, 'fee'),
      onFilter: (value, record) => record.fee === value,

    },
    {
      title: 'Collection Date',
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      align: 'center',
      width: '90px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text ? moment(text).format('YYYY-MM-DD') : null}</div>;
      },
      filters: frameFilterArray(gstTaskData, 'collectionDate'),
      onFilter: (value, record) => record.collectionDate === value,
    },
    {
      title: 'Log',
      dataIndex: '_id',
      align: 'center',
      width: '40px',
      onCell: () => ({
        onClick: event => { event.stopPropagation(); },
      }),
      render(text) {
        return (
          <Button
            type="link"
            className="client-info-button client-info"
            onClick={() => OpenChangelogHandler(text)}
          >
            <FontAwesomeIcon icon={faClockRotateLeft} className="changelog" />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> GstTask's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4 client-wrapper">
        <SearchTable
          columns={columns}
          data={gstTaskData}
          searchItems={['clientName', 'Status', 'collectionDate']}
          onRowClickHandler={onRowClickHandler}
        />
      </div>
      <DrawerGstTask show={show} setShow={setShow} recordType={recordType} rowValue={rowValue} setRowValue={setRowValue} />
      <DrawerClients show={clientsShow} setShow={setClientsShow} clientType={'readonly'} rowValue={rowValue} id={rowValue?.clientId} />
      <DrawerGstLog show={logsShow} setShow={setLogsShow} id={logValue} />
    </>
  );
};

export default GstTask;

const steps = [
  'Contact the Client',
  'Tally Entry',
  'GSTR-1 Filing',
  'GSTR-2B Reconciliation',
  'GSTR-3B Upload',
  'Inform the Payment',
  'Collection of the Payment',
  'GSTR-3B Filing',
  'Challan Download',
  'Task Completed'
];

const stepsFilter = [
  { text: 'Contact the Client', value: 0 },
  { text: 'Tally Entry', value: 1 },
  { text: 'GSTR-1 Filing', value: 2 },
  { text: 'GSTR-2B Reconciliation', value: 3 },
  { text: 'GSTR-3B Upload', value: 4 },
  { text: 'Inform the Payment', value: 5 },
  { text: 'Collection of the Payment', value: 6 },
  { text: 'GSTR-3B Filing', value: 7 },
  { text: 'Challan Download', value: 8 },
  { text: 'Task Completed', value: 9 },
];
