import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All Billing
export const getBillings = createAsyncThunk('billings/getBillings', async (arg) => {
  try {
    const response = await axios.get(`${urls.billingUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All Billing
export const getBilling = createAsyncThunk('billings/getBilling', async () => {
  try {
    const response = await axios.get(`${urls.billingUrl.url}`);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Get All get Billing Fo rDashboard
export const getBillingForDashboard = createAsyncThunk(
  'billings/billingdashboard',
  async (args) => {
    const { clientId, fromDate, toDate } = args;
    try {
      const response = await axios.get(`${urls.billingUrl.url}/billingdashboard`, {
        params: {
          clientId,
          fromDate,
          toDate
        }
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

// Get All get Billing Fo rDashboard
export const getBillingForTaxDashboard = createAsyncThunk(
  'billings/taxBillingdashboard',
  async (args) => {
    const { clientId, fromDate, toDate } = args;
    try {
      const response = await axios.get(`${urls.billingUrl.url}/taxBillingdashboard`, {
        params: {
          clientId,
          fromDate,
          toDate
        }
      });
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

// Create Billing
export const createBilling = createAsyncThunk('billings/createBilling', async (arg) => {
  try {
    const response = await axios.post(`${urls.billingUrl.url}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Update Billing
export const updateBilling = createAsyncThunk('billings/updateBilling', async (arg) => {
  try {
    const response = await axios.patch(`${urls.billingUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});

// Delete Billing
export const deleteBilling = createAsyncThunk('billings/deleteBilling', async (arg) => {
  try {
    const response = await axios.delete(`${urls.billingUrl.url}/${arg._id}`, arg);
    return response.data;
  } catch (err) {
    return err;
  }
});
