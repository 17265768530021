import moment from "moment";
import { toast } from "react-toastify";
import swal from "sweetalert";
import _ from "lodash";

export const ChangeToValidNumber = (value, type) => {
  let converted = parseFloat(value).toFixed(2);

  if (type && type === "number") {
    converted = parseInt(converted, 10).toFixed(0);
  }
  return Number.isNaN(converted)
    ? type && type == "number"
      ? "0"
      : "0.00"
    : converted;
};

export const ShowMessage = (type, message) => toast[type](message, {
  position: toast.POSITION.TOP_LEFT,
});

export const CalculateTotal = (array, param, type) => {
  if (array && array.length > 0) {
    const data = [...array];
    return ChangeToValidNumber(
      data
        .reduce(
          (total, currentValue) =>
          (total +=
            (currentValue && currentValue[param]
              ? parseFloat(currentValue[param].toString().replace(",", ""))
              : 0)),
          0
        )
        .toFixed(2),
      type
    );
  }
  return 0.0;

};

export const ArraySearch = (array, searchText, searchItems) => {
  const lowSearch = searchText.toLowerCase();
  return array.filter((value) =>
    searchItems.some((key) =>
      String(value[key]).toLowerCase().includes(lowSearch)
    )
  );
};

export const DateSearch = (array, searchItems, startDate, endDate) => array.filter((value) =>
  searchItems.some(
    (key) =>
      // String(value[key]).toLowerCase().includes(lowSearch)
      value[key].getTime() <= endDate && value[key].getTime() >= startDate
  )
);

export const DateConvert = (date, format) => moment(date).format(format);

export const CommaToFixed = (value) => value ? Math.abs(parseFloat(value.replace(/,/g, ""))).toFixed(2) : 0.0;

export const GetUniqueListBy = (arr, key) => [...new Map(arr.map((item) => [item[key], item])).values()];
export const Confirmation = (
  title,
  text,
  icon,
  buttons,
  dangerMode,
  content
) => swal({
  title,
  text,
  icon,
  buttons: buttons || null,
  dangerMode,
  content: content || null,
});

export const securityCheck = (submenu, rightArray) => {
  if (submenu.children && submenu.children.length > 0) {
    let securityCodeAvailable = false;
    submenu.children.map((subChild) => {
      if (rightArray.find((right) => right == subChild.securityCode)) {
        securityCodeAvailable = true;
      }
    });
    return securityCodeAvailable;
  }
  return rightArray.find((right) => right == submenu.securityCode);

};

export const NumberToWords = (input) => {
  /* eslint-disable eqeqeq */

  const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

  const getLT20 = (n) => a[Number(n)];
  const getGT20 = (n) => `${b[n[0]]} ${a[n[1]]}`;

  const num = Number(input);
  if (isNaN(num)) return "";
  if (num === 0) return "zero";

  const numStr = num.toString();
  if (numStr.length > 9) {
    throw new Error("overflow"); // Does not support converting more than 9 digits yet
  }

  const [, n1, n2, n3, n4, n5] = (`000000000${numStr}`).substr(-9).match(regex); // left pad zeros

  let str = "";
  str += n1 != 0 ? `${getLT20(n1) || getGT20(n1)}crore ` : "";
  str += n2 != 0 ? `${getLT20(n2) || getGT20(n2)}lakh ` : "";
  str += n3 != 0 ? `${getLT20(n3) || getGT20(n3)}thousand ` : "";
  str += n4 != 0 ? `${getLT20(n4)}hundred ` : "";
  str += n5 != 0 && str != "" ? "and " : "";
  str += n5 != 0 ? getLT20(n5) || getGT20(n5) : "";

  return str.trim();
};

export const numberFormat = (value) =>
  value.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
// new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(value);

export const frameFilterArray = (array, key) => {
  const res = [];

  array?.forEach((item) => item[key] && res.push({ "text": item[key], "value": item[key] }))
  return _.uniqBy(res, 'value');
}

export const ordinal = (value) => moment.localeData().ordinal(parseInt(value, 10))

export const formatMonthYear = (row) => moment(`${row?.month}-${row?.year}`, "MM-YYYY").format("MMMM YYYY")

export const formatMonthYearToTime = (row) => new Date(`${row?.year}-${row?.month}`).getTime();

export const frameFilterDate = (array) => {
  const res = [];
  array?.forEach((item) => res.push({ "text": `${moment(`${item?.month}-${item?.year}`, "MM-YYYY").format("MMMM YYYY")}`, "value": `${moment(`${item?.month}-${item?.year}`, "MM-YYYY").format("MMMM YYYY")}` }))
  return _.uniqBy(res, 'value');
}

export const formatDatetoDb = (date) => date ? moment(date.length > 10 ? date : moment(date, "DD/MM/YYYY")).format('YYYY-MM-DD') : null


export const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}