import InputContainer from '../../../components/InputContainer/InputContainer';

const AcknowledgementDetails = ({ mode, drawerType, data, formErrors, handleChange, handleSelectChangeHandler }) => (
  <>
    <div className="mt-4 header">Acknowledgement Details</div>
    <hr />
    <div className="d-flex flex-wrap">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="PAN Acknowledgement"
        type="text"
        id="panAcknowledgement"
        name="panAcknowledgement"
        value={data?.panAcknowledgement}
        onChange={handleChange}
        placeholder="Enter PAN Acknowledgement"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="PAN Delivered Date"
        type="prime-date"
        format="dd/mm/yy"
        id="panDeliveredDate"
        name="panDeliveredDate"
        picker="date"
        value={data?.panDeliveredDate}
        onChange={handleChange}
        placeholder="Select PAN Delivered Date"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        required
        label="Status"
        type="select"
        id="status"
        name="status"
        value={data?.status}
        error={formErrors?.status}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'status', 'value')}
        options={statusOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select the Status"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
    <div>
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Remarks"
        type="textarea"
        id="remarks"
        name="remarks"
        value={data?.remarks}
        onChange={handleChange}
        placeholder="Enter Remarks"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1`}
      />
    </div>
  </>
);

export default AcknowledgementDetails;

// Status Options
const statusOptions = [
  { value: 'Application to be Sent', label: 'Application to be Sent' },
  { value: 'Application Sent', label: 'Application Sent' },
  { value: 'Acknowledgement Received', label: 'Acknowledgement Received' },
  { value: 'PAN Card Delivered', label: 'PAN Card Delivered' },
];
