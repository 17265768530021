import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    user: {}
};

const authSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        authLogin(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        authLogout(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { authLogin, authLogout } = authSlice.actions
export default authSlice.reducer