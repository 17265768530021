/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { difference } from '../../components/Utils';

import { getAllLogs } from '../../STORE/MiddleWare/Utils';
import { UseLogs } from '../../STORE/Selector/Utils';

// eslint-disable-next-line react/prop-types
const DrawerItLog = ({ show, setShow, id }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const LOGS = UseLogs();
  useEffect(() => {
    setData(LOGS);
  }, [LOGS]);

  useEffect(() => {
    if (show && id) {
      dispatch(getAllLogs({
        pageItemId: id,
        page: "ItTasks"
      })).then(() => {
        setOpen(show);
      });
    }
  }, [show, id])

  const onClose = () => {
    setOpen(false);
    setShow(false);
  };

  const differences = []
  data?.forEach(element => {
    const dif = difference(element?.newvalue, element?.previousvalue);
    differences.push(dif);
  });

  return (
    <>
      <Drawer
        title='Log'
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <div>
          <LogGenerator differences={differences} />
        </div>
      </Drawer>
    </>
  );
};

export default DrawerItLog;


const LogGenerator = ({ differences }) =>
  <div className='log-wrapper'>
    {differences?.map(diff => (
      // eslint-disable-next-line react/jsx-key
      <div className='log-container'>
        {Object.entries(diff).map(([key, value]) => (<div key={key} className='log-item'>
          <strong>{gstTasksLabels[key]} : </strong>
          {(key === "updatedAt" || key === "dataCollectedDate" || key === "dataCollectedDate" || key === "statementPreparedDate" || key === "collectionDate" || key === "paidDate" || key === "itFiledDate" || key === "eVerifiedDate" || key === "printingDate" || key === "distributionDate" || key === "refundCreditedDate" || key === "fromDate" || key === "toDate") ? moment(value).format('YYYY-MM-DD hh:mm A') :
            (key === "currentUser") ? value.name :
              (key === "gstPayer" || key === "receivedGstReturns") ? (value ? "Yes" : "No") :
                (key === "gstr2bReconciliation") ? gstTasksLabels[value] :
                  (key === "step") ? steps[value] :
                    (typeof value === "object") ? <div>{ObjectToTable(value)}</div> : value}
        </div>
        )
        )}
      </div>
    ))}
  </div>

const ObjectToTable = (arr) => (
  <table className='log-table'>
    {
      arr?.map((item, index) => (
        <>
          {typeof item === "string" ? <tr><td>{item}</td></tr> :
            <>
              {index === 0 ? <tr>
                {Object.entries(item)?.map(([key]) => <th key={key} className='log-item'>{gstTasksLabels[key]}</th>)}
              </tr>
                : <></>}
              <tr key={index}>
                {Object.entries(item)?.map(([key, value]) => <td key={key} className='log-item'>{key === "reminderDate" ? moment(value).format('YYYY-MM-DD hh:mm A') : value}</td>)}
              </tr>
            </>
          }
        </>
      ))}
  </table>
)
const gstTasksLabels = {
  "notes": "Notes",
  "reminderDate": "Reminder Date",
  "feedback": "Feedback",
  "gstPayer": "GST Payer",
  "receivedGstReturns": "Received GST Returns",
  "documentList": "Document List",
  "deductions": "Deductions",
  "movableAndImmovable": "Movable and Immovable Property Details",
  "shareTrading": "Share Trading & SIP Detail",
  "others": "Others",
  "dataCollectedDate": "Data Collected Date",
  "itrType": "ITR Type",
  "tax": "Tax",
  "refund": "Refund",
  "statementPreparedDate": "Statement Prepared Date",
  "taxOrRefund": "Tax Value",
  "fee": "Fee",
  "penalty": "Penalty",
  "interest": "Interest",
  "totalItPayment": "Total IT Payment",
  "taxCollected": "Tax Collected",
  "feeCollected": "Fee Collected",
  "collectionDate": "Collection Date",
  "modeOfPayment": "Mode of Payment",
  "downloadPaidChallan": "Download Paid Challan",
  "paidDate": "Paid Date",
  "acknowledgementNumber": "Acknowledgement Number",
  "itFiledDate": "IT Filed Date",
  "aadhaarLinkedMobileNumber": "Aadhaar Linked Mobile Number",
  "itrCopyDownload": "ITR Copy Download",
  "eVerifiedDate": "E-Verified Date",
  "serialNumber": "Serial Number",
  "printingDate": "Printing Date",
  "fileDistributedTo": "File Distributed To",
  "distributionDate": "Distribution Date",
  "refundAmount": "Refund Amount",
  "refundCreditedDate": "Refund Credited Date",
  "step": "Status",
  "bank": "Bank",
  "ifsc": "Branch or IFSC Code",
  "type": "Type",
  "accountNo": "Account Number",
  "fromDate": "From Date",
  "toDate": "To Date",
  "status": "Status",
  "updatedAt": "Updated At",
  "currentUser": "Updated By",
  'DataMatching': 'Data is Matching',
  'DataNotMatching': 'Data is Not Matching',
}
const steps = [
  'Contact the Client',
  'Statement Preparation',
  'Inform the Payment',
  'Collection of the Payment',
  'Tax Payment',
  'Income Tax Filling',
  'E-verification',
  'Print',
  'Distribution',
  'Refund Follow Up',
  'Task Completed'
];
