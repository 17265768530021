import { createSlice } from '@reduxjs/toolkit'
import { getAllItDueDates, getItDueDate } from '../MiddleWare/ItDueDates';

const initialState = {
    itDueDatesData: [],
    itDueDatesMaster: {},
    itDueDates: null
};

const itDueDatesSlice = createSlice({
    name: 'itDueDates',
    initialState,
    reducers: {
        setItDueDates(state, action) {
            return {
                ...state,
                itDueDatesData: action.payload ? action.payload.company : [],
            };
        },
        updateItDueDates(state, action) {
            const oldData = [...state.itDueDatesData];
            const newdata = oldData.filter((due) => due._id !== action.payload._id);
            newdata.push(action.payload);

            return {
                ...state,
                itDueDatesData: newdata || [],
            };
        },
        createItDueDates(state, action) {
            const newdata = [...state.itDueDatesData];
            newdata.push(action.payload);

            return {
                ...state,
                itDueDatesData: newdata || [],
            };
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getAllItDueDates.pending, () => { })

        builder.addCase(getAllItDueDates.fulfilled, (state, action) => {
            if (action.payload) {
                state.itDueDatesData = action.payload
                state.itDueDatesMaster = action.payload?.itDueDateMaster || {}
            }
        })

        builder.addCase(getAllItDueDates.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getItDueDate.pending, () => { })

        builder.addCase(getItDueDate.fulfilled, (state, action) => {
            if (action.payload) {
                state.itDueDates = action.payload
            }
        })

        builder.addCase(getItDueDate.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
                state.loading = true;
            }
        })
    }
})

export const { createItDueDate, setItDueDate, updateItDueDate } = itDueDatesSlice.actions
export default itDueDatesSlice.reducer