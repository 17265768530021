import InputContainer from '../../../components/InputContainer/InputContainer';

const PANDetails = ({ mode, drawerType, data, formErrors, handleSelectChangeHandler, handleChange }) => (
  <>
    <div className="mt-4 header">PAN Details</div>
    <hr />
    <div className="d-flex">
      <InputContainer
        required
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Application Type"
        type="select"
        id="applicationType"
        name="applicationType"
        value={data?.applicationType}
        error={formErrors?.applicationType}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'applicationType', 'value')}
        options={filingOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select Application Type"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        required
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="PAN Card Type"
        type="select"
        id="panCardType"
        name="panCardType"
        value={data?.panCardType}
        error={formErrors?.panCardType}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSelectChangeHandler.bind(this, 'panCardType', 'value')}
        options={PANTypeOptions}
        optionsValueKey="value"
        optionsLabelKey="label"
        placeholder="Select PAN Card Type"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
    <div className="d-flex">
      <InputContainer
        label="Application Date"
        type="prime-date"
        id="applicationDate"
        format="dd/mm/yy"
        name="applicationDate"
        value={data?.applicationDate}
        picker="date"
        onChange={handleChange}
        placeholder="Enter Application  Date"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
        disabled={!!(mode === 'view' && drawerType === 'existing')}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Fee Collected"
        type="number"
        id="feeCollected"
        name="feeCollected"
        value={data?.feeCollected}
        onChange={handleChange}
        placeholder="Enter Fee Collected"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
  </>
);

export default PANDetails;

// Filing Options
const filingOptions = [
  { value: 'New', label: 'New' },
  { value: 'Correction', label: 'Correction' },
];

const PANTypeOptions = [
  { value: 'Individual (P)', label: 'Individual (P)' },
  { value: 'Partnership Firm (F)', label: 'Partnership Firm (F)' },
  { value: 'Trust (T)', label: 'Trust (T)' },
  { value: 'Company (C)', label: 'Company (C)' },
  { value: 'Limited Liability Partnership (E)', label: 'Limited Liability Partnership (E)' },
  { value: 'Hindu Undivided Family (H)', label: 'Hindu Undivided Family (H)' },
  { value: 'Association of Person (A)', label: 'Association of Person (A)' },
  { value: 'Local Authority (L)', label: 'Local Authority (L)' },
  { value: 'Body of Individual (B)', label: 'Body of Individual (B)' },
  { value: 'Government Agency (G)', label: 'Government Agency (G)' },
  { value: 'Artificial Judicial Person (J)', label: 'Artificial Judicial Person (J)' },
];
