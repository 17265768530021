import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get GstDueDate
export const getGstDueDate = createAsyncThunk('gstDueDate/getGstDueDate', async () => {
    try {
        const response = await axios.get(`${urls.gstDueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Get All GstDueDate
export const getAllGstDueDates = createAsyncThunk('gstDueDate/getAllGstDueDates', async () => {
    try {
        const response = await axios.get(`${urls.gstDueDatesHistoryUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Create GstDueDate
export const createGstDueDate = createAsyncThunk('gstDueDate/createGstDueDate', async (arg,) => {
    try {
        const response = await axios.post(`${urls.gstDueDatesUrl.url}`, arg);
        return response.data
    } catch (err) {
        return err
    }
})

// Update GstDueDate
export const updateGstDueDate = createAsyncThunk('gstDueDate/updateGstDueDate', async (arg,) => {
    try {
        const response = await axios.patch(`${urls.gstDueDatesUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Update GstDueDate History
export const updateGstDueDateHistory = createAsyncThunk('gstDueDate/updateGstDueDateHistory', async (arg,) => {
    try {
        const response = await axios.patch(`${urls.gstDueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Delete GstDueDate
export const deleteGstDueDate = createAsyncThunk('gstDueDate/deleteGstDueDate', async (arg,) => {
    try {
        const response = await axios.delete(`${urls.gstDueDatesHistoryUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})