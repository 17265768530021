import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import axios from '../../axios/index';
import urls from '../../axios/url';

// Get All Clients
export const getClientsAutoComplete = createAsyncThunk('autocomplete/clients', async (arg) => {
    try {
        const response = await axios.get(`${urls.clientsAutocomplete.url}`, arg);
        return response.data;
    } catch (err) {
        return err;
    }
});