import { PrimeReactProvider } from 'primereact/api';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

// SCSS
import "./App.scss";


// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <PrimeReactProvider>
        <ScrollToTop />
        <StyledChart />
        <Router />
      </PrimeReactProvider>
    </ThemeProvider>
  );
}
