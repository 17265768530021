/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import InputContainer from '../../../components/InputContainer/InputContainer';

const Partner = ({ mode, clientType, data, handleChange, partner, setPartner, emptyPartner, disable }) => {
  const containerClass = mode === 'view' && clientType === 'existing' ? 'custom-disable' : '';

  // const PartnerChangeHandler = (e, index) => {
  //   const { name, value } = e.target;
  //   const existingPartner = [...partner];

  //   existingPartner[index][name] = value;
  //   setPartner([...existingPartner]);
  // };

  const PartnerDateChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const existingPartner = [...partner];

    existingPartner[index][name] = value;
    setPartner([...existingPartner]);
  };

  const PartnerChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const existingPartner = partner.map((p, i) => i === index ? { ...p } : p);

    if (name === "partnerAadhaarNo" && value.length > 12) {
      return;
    }

    existingPartner[index][name] = value;
    setPartner(existingPartner);
  };

  const addPartnerHandler = () => {
    setPartner([...partner, emptyPartner[0]]);
  };
  const deletePartnerHandler = (index) => {
    setPartner([...partner.toSpliced(index, 1)]);
  };
  const items = [
    {
      key: '1',
      label: 'Partner Detail',
      children: (
        <div>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={24} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Certificate No."
                  type="text"
                  id="partnerCertificateNo"
                  name="partnerCertificateNo"
                  value={data?.partnerCertificateNo}
                  onChange={handleChange}
                  placeholder="Enter Certificate Number"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Registered Date"
                  type="prime-date"
                  id="partnerRegisteredDate"
                  name="partnerRegisteredDate"
                  value={data?.partnerRegisteredDate}
                  onChange={handleChange}
                  placeholder="Choose Registered Date"
                  picker="date"
                  format="dd/mm/yy"
                  disabled={disable}
                />
              </Col>
              <Col span={12} className="mb-2">
                <InputContainer
                  containerClassName={containerClass}
                  label="Last Renewed Date"
                  type="prime-date"
                  id="partnerLastRenewedDate"
                  name="partnerLastRenewedDate"
                  value={data?.partnerLastRenewedDate}
                  onChange={handleChange}
                  placeholder="Choose Last Renewed Date"
                  picker="date"
                  format="dd/mm/yy"
                  disabled={disable}
                />
              </Col>
              <Col span={24} className="mt-2 header">
                Partners
              </Col>
              <div className='ps-3 pb-4'>
                {partner.map((item, index) => (
                  <div className="d-flex flex-wrap" key={index}>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner Name"
                        type="text"
                        id="partnerName"
                        value={item.partnerName}
                        name="partnerName"
                        onChange={(e) => PartnerChangeHandler(e, index)}
                        placeholder="Enter Partner Name"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner's Father Name"
                        type="text"
                        id="partnerFatherName"
                        name="partnerFatherName"
                        value={item.partnerFatherName}
                        onChange={(e) => {
                          PartnerChangeHandler(e, index);
                        }}
                        placeholder="Enter Partner's Father Name"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner DOB"
                        type="prime-date"
                        id="partnerDob"
                        name="partnerDob"
                        value={item.partnerDob}
                        onChange={(e) => PartnerDateChangeHandler(e, index)}
                        placeholder="Choose Partner DOB"
                        picker="date"
                        format="dd/mm/yy"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner PAN Number"
                        type="text"
                        id="partnerPanNo"
                        name="partnerPanNo"
                        value={item.partnerPanNo}
                        onChange={(e) => PartnerChangeHandler(e, index)}
                        placeholder="Enter Partner PAN Number"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner Aadhaar Number"
                        type="number"
                        id="partnerAadhaarNo"
                        name="partnerAadhaarNo"
                        value={item.partnerAadhaarNo}
                        onChange={(e) => PartnerChangeHandler(e, index)}
                        placeholder="Enter Partner Aadhaar Number"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner Address"
                        type="text"
                        id="partnerAddress"
                        name="partnerAddress"
                        value={item.partnerAddress}
                        onChange={(e) => PartnerChangeHandler(e, index)}
                        placeholder="Enter Partner Address"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner DOJ"
                        type="prime-date"
                        id="partnerDoj"
                        name="partnerDoj"
                        value={item.partnerDoj}
                        onChange={(e) => PartnerDateChangeHandler(e, index)}
                        placeholder="Choose Partner DOJ"
                        picker="date"
                        format="dd/mm/yy"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner DOR"
                        type="prime-date"
                        id="partnerDor"
                        name="partnerDor"
                        value={item.partnerDor}
                        onChange={(e) => PartnerDateChangeHandler(e, index)}
                        placeholder="Choose Partner DOR"
                        picker="date"
                        format="dd/mm/yy"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner Mobile Number"
                        type="number"
                        id="partnerMobileNumber"
                        name="partnerMobileNumber"
                        value={item.partnerMobileNumber}
                        onChange={(e) => PartnerChangeHandler(e, index)}
                        placeholder="Enter Partner Mobile Number"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col span={12} className="mb-2">
                      <InputContainer
                        label="Partner DOA"
                        type="prime-date"
                        id="partnerDoa"
                        name="partnerDoa"
                        value={item.partnerDoa}
                        onChange={(e) => PartnerDateChangeHandler(e, index)}
                        placeholder="Choose Partner DOA"
                        picker="date"
                        format="dd/mm/yy"
                        containerClassName={containerClass}
                        disabled={disable}
                      />
                    </Col>
                    <Col className="col-md-12 d-flex align-items-center justify-content-end">
                      {mode === 'edit' ? (
                        <div>
                          <Button type="primary" danger className="mt-2" onClick={() => deletePartnerHandler(index)}>
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col span={24}>
                      <hr />
                    </Col>
                  </div>
                ))}

                <Col>
                  {mode === 'edit' ? (
                    <Button type="primary" className="mt-2" onClick={addPartnerHandler}>
                      Add New Partner
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
              </div>
            </Row>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        {items.map((item) => (
          <div key={item.key}>{item.children}</div>
        ))}
      </div>
    </div>
  );
};
export default Partner;
