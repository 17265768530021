/* eslint-disable react/prop-types */
import { Avatar, Card, Col, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import InputContainer from '../../components/InputContainer/InputContainer';

const ClientCard = ({ data, pageName, children }) => {
  const labels = [
    data?.serviceGstStatus && 'GST',
    data?.serviceItStatus && 'IT',
    data?.lpEwayStatus && 'E-Way',
    data?.lpEInvoiceStatus && 'E-Invoice',
    data?.serviceDtsStatus && 'TDS',
    data?.serviceFirmStatus && 'Firm Renewal',
    data?.serviceAWStatus && 'Accounts Writing',
  ].filter(Boolean);

  return (
    <Card className="client-card">
      <Row>
        <Row span={24} className="w-100">
          <Col span={12} className="text-capitalize">
            <span className="custom-client">{data?.clientName} </span>
            <span className="client-id">{data?.clientId ? `(${data.clientId})` : ''}</span>
          </Col>
          <Col span={12} className="text-capitalize">
            {children}
          </Col>
        </Row>
        <Col span={12} className='mb-4 client-contact'>
          {data?.glContactCity || ""}
        </Col>
        <Col span={24} className="mb-3 d-flex gap-2 client-service">
          {labels.map((service, index) => (
            <div key={index} className="rounded services-item-border">
              {service}
            </div>
          ))}
        </Col>
        <Col span={24} className="mb-2 d-flex flex-column">
          <Row className="d-flex">
            <Col span={8} className="d-flex">
              {data?.glContactOwnerName || data?.glContactMobileNo ? (
                <Avatar size={38} icon={<UserOutlined />} className="mt-2 contact-icon" />
              ) : (
                <></>
              )}
              <div className="d-flex flex-column">
                <div className="client-name text-capitalize">{data?.glContactOwnerName}</div>
                <div className="client-contact">{data?.glContactMobileNo}</div>
              </div>
            </Col>
            <Col span={16}>
              {pageName === "GST Tasks" ?
                <div className="d-flex flex-column">
                  <p className='pb-0 mb-2'><span className='client-gst'>GSTIN : </span>{data?.lpGstIn}</p>
                  {/* <p className='pb-0 mb-0'><span className='client-gst'>GST User Name : </span>{data?.lpGstusername}</p> */}
                  <p className='pb-0 mb-0 d-flex align-items-center'>
                    <span className='client-gst'>GST User Name : </span>
                    <InputContainer
                      inputClassName='position-relative'
                      type="text"
                      disabled
                      id="lpGstusername"
                      name="lpGstusername"
                      value={data?.lpGstusername}
                      showClipboard
                      className="border-0 bg-transparent"
                    /></p>

                  <p className='pb-0 mb-0 d-flex align-items-center'>
                    <span className='client-gst'>GST Password : </span>
                    <InputContainer
                      type="password"
                      id="lpGstPassword"
                      name="lpGstPassword"
                      value={data?.lpGstPassword}
                      disabled
                      showClipboard
                      className="border-0 bg-transparent"
                    /></p>
                </div>
                : <></>
              }
              {pageName === "IT Tasks" ?
                <div className="d-flex flex-column">
                  <p className='pb-0 mb-0 d-flex align-items-center'>
                    <span className='client-gst'>IT Mail : </span>
                    <InputContainer
                      containerClassName="w-250-px"
                      inputClassName='position-relative'
                      type="text"
                      disabled
                      id="lpItMail"
                      name="lpItMail"
                      value={data?.lpItMail}
                      showClipboard
                      className="border-0 bg-transparent"
                    />
                  </p>
                  <p className='pb-0 mb-0 d-flex align-items-center'>
                    <span className='client-gst'>IT Mail Password : </span>
                    <InputContainer
                      type="password"
                      id="lpItPassword"
                      name="lpItPassword"
                      value={data?.lpItPassword}
                      disabled
                      showClipboard
                      className="border-0 bg-transparent"
                    /></p>
                </div>
                : <></>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ClientCard;
