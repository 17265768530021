import InputContainer from '../../../components/InputContainer/InputContainer';

const SendingDetails = ({ mode, drawerType, data, formErrors, handleChange }) => (
  <>
    <div className="mt-4 header">Sending Details</div>
    <hr />
    <div className="d-flex">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Sent Date"
        type="prime-date"
        id="date"
        format="dd/mm/yy"
        name="sentDate"
        picker="date"
        value={data?.sentDate}
        onChange={handleChange}
        placeholder="Select Sent Date"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Via"
        type="text"
        id="via"
        name="via"
        value={data?.via}
        onChange={handleChange}
        placeholder="Enter Via"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
    <div className="d-flex">
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="To"
        type="text"
        id="to"
        name="to"
        value={data?.to}
        onChange={handleChange}
        placeholder="Enter To"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
      <InputContainer
        disabled={!!(mode === 'view' && drawerType === 'existing')}
        label="Courier Tracker ID"
        type="text"
        id="courierTrackerId"
        name="courierTrackerId"
        value={data?.courierTrackerId}
        onChange={handleChange}
        placeholder="Enter Courier Tracker ID"
        containerClassName={`${mode === 'view' ? 'custom-disable' : ''} pe-3 pb-1 col-md-6`}
      />
    </div>
  </>
);

export default SendingDetails;
