import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';
import { getAllLogs } from '../MiddleWare/Utils';

const initialState = {
    loaderShow: false,
    id: null,
    pageName: "",
    headerCalendarPages: ['gst-task'],
    headerFinancialYearPages: ['it-task'],
    viewMonth: {
        month: moment(new Date()).subtract(1, 'months').format('MM'),
        year: moment(new Date()).subtract(1, 'months').format('YYYY'),
    },
    viewFinancialYear: `${moment(new Date()).format('YYYY')}-${moment(new Date()).add(1, 'years').format('YYYY')}`,
    logs: []
};

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        loaderShow(state) {
            return {
                ...state,
                loaderShow: true,
            }
        },
        loaderHide(state) {
            return {
                ...state,
                loaderShow: false,
            }
        },
        updateViewMonth(state, action) {
            return {
                ...state,
                viewMonth: {
                    month: moment(action.payload).format('MM'),
                    year: moment(action.payload).format('YYYY'),
                }
            }
        },
        updateFinancialYear(state, action) {
            return {
                ...state,
                viewFinancialYear: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getAllLogs.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAllLogs.fulfilled, (state, action) => {
            if (action.payload) {
                state.logs = action.payload.data;
            }
            state.loading = false;
        });

        builder.addCase(getAllLogs.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message;
                state.loading = false;
            }
        });
    }
})

export const { loaderShow, loaderHide, updateViewMonth, updateFinancialYear } = utilsSlice.actions
export default utilsSlice.reducer