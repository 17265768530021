import { Button, Space } from 'antd';

// eslint-disable-next-line react/prop-types
const DrawerButtons = ({ showSave, showProceed, onClose, onSubmitHandler }) => (
  <Space>
    <Button onClick={onClose}>Cancel</Button>
    {showSave ?
      <Button type="primary" onClick={() => onSubmitHandler(false)}>
        Save
      </Button>
      : <></>
    }
    {showProceed ?
      <Button type="primary" onClick={() => onSubmitHandler(true)}>
        Proceed
      </Button>
      : <></>
    }
  </Space>
);

export default DrawerButtons;
