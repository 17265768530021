import { createSlice } from '@reduxjs/toolkit';
import { getBillings, getBilling, getBillingForDashboard, getBillingForTaxDashboard } from '../MiddleWare/Billing';

const initialState = {
  billingsData: [],
  billingsDataforDashboard: [],
  billingsDataforTaxDashboard: [],
  billing: null,
  searchValue: {
    clientId: '',
    fromDate: '',
    toDate: ''
  },
  taxSearchValue: {
    clientId: '',
    fromDate: '',
    toDate: ''
  }
};

const billingSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    setBillings(state, action) {
      return {
        ...state,
        billingsData: action.payload ? action.payload.company : [],
      };
    },
    updateBillings(state, action) {
      const oldData = [...state.billingsData];
      const newdata = oldData.filter((billing) => billing._id !== action.payload._id);
      newdata.push(action.payload);

      return {
        ...state,
        billingssData: newdata || [],
      };
    },
    createBillings(state, action) {
      const newdata = [...state.billingsData];
      newdata.push(action.payload);

      return {
        ...state,
        billingsData: newdata || [],
      };
    },
    updateSearchValue(state, action) {
      return {
        ...state,
        searchValue: action.payload
      };
    },
    updateTaxSearchValue(state, action) {
      return {
        ...state,
        taxSearchValue: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBillings.pending, () => { });

    builder.addCase(getBillings.fulfilled, (state, action) => {
      if (action.payload) {
        state.billingsData = action.payload;
      }
    });

    builder.addCase(getBillings.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBilling.pending, () => { });

    builder.addCase(getBilling.fulfilled, (state, action) => {
      if (action.payload) {
        state.billing = action.payload;
      }
    });

    builder.addCase(getBilling.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBillingForDashboard.pending, () => { });

    builder.addCase(getBillingForDashboard.fulfilled, (state, action) => {
      if (action.payload) {
        state.billingsDataforDashboard = action.payload.billing;
      }
    });

    builder.addCase(getBillingForDashboard.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBillingForTaxDashboard.pending, () => { });

    builder.addCase(getBillingForTaxDashboard.fulfilled, (state, action) => {
      if (action.payload) {
        state.billingsDataforTaxDashboard = action.payload.GstTask;
      }
    });

    builder.addCase(getBillingForTaxDashboard.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export const { createBillings, setBillings, updateBillings, updateSearchValue, updateTaxSearchValue } = billingSlice.actions;
export default billingSlice.reducer;
