import { createSlice } from '@reduxjs/toolkit';
import { getUsers, getUser, createUser, updateUser, deleteuser } from '../MiddleWare/Users';

const initialState = {
  usersData: [],
  user: null,
  error: '',
  loading: false,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUsers.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.usersData = action.payload.user;
      }
      state.loading = false;
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = false;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.pending, () => { });

    builder.addCase(getUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.user;
      }
    });

    builder.addCase(getUser.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createUser.pending, () => { });

    builder.addCase(createUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.user;
      }
    });

    builder.addCase(createUser.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.message;
        state.loading = true;
      }
    });
  },
});

export default userSlice.reducer;
