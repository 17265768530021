import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { getClientsGst } from '../../../../STORE/MiddleWare/Clients';
import { UseClientGst } from '../../../../STORE/Selector/Clients';

// Component
import TableContainer from '../../../../components/Table/Table';
import { formatMonthYear, formatMonthYearToTime, frameFilterDate } from '../../../../components/Utils';

const Gst = ({ clientId, open }) => {
    const dispatch = useDispatch();
    // State
    const [gstData, setGstData] = useState([]);

    // Custom Selector Hooks
    const gst = UseClientGst();

    useEffect(() => {
        if (clientId && open)
            dispatch(getClientsGst({ clientId }));;
    }, [clientId, open]);

    useEffect(() => {
        setGstData(gst?.GstTask);
    }, [gst]);


    const columns = [
        {
            title: 'S.No',
            dataIndex: 's.no',
            align: 'center',
            width: '50px',
            onCell: () => ({ className: '' }),
            render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
            fixed: 'left',
        },
        {
            title: 'Month',
            dataIndex: 'month',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: 'min-width-150-px' }),
            render(text, row) {
                return text ? <div>{formatMonthYear(row)}</div> : <></>;
            },
            filters: frameFilterDate(gstData),
            onFilter: (value, record) => formatMonthYear(record) === value,
            sorter: (a, b) => formatMonthYearToTime(a) - formatMonthYearToTime(b),
        },
        {
            title: 'Acknowledgement No',
            dataIndex: 'acknowledgementNo',
            key: 'acknowledgementNo',
            align: 'center',
            width: '130px',
            onCell: () => ({ className: ' ' }),
            render(text) {
                return <div>{text}</div>;
            },
        },
        {
            title: 'Mode of Payment',
            dataIndex: 'modeOfPayment',
            key: 'modeOfPayment',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' ' }),
            render(text) {
                return <div>{text}</div>;
            },
        },
        {
            title: 'Total GST Payment',
            dataIndex: 'totalGstPayment',
            key: 'totalGstPayment',
            align: 'center',
            width: '150px',
            onCell: () => ({ className: ' ' }),
            render(text) {
                return <div>{text}</div>;
            },
        },
    ];


    return (
        <div className="row client-wrapper">
            <TableContainer columns={columns} data={gstData} />
        </div>
    );
};

export default Gst;
