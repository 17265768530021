import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "antd";

import DrawerClients from "../../clients/DrawerClients";
import SearchTable from "../../../components/Table/SearchTable";
import DrawerBilling from "../DrawerBilling";
import { frameFilterArray } from "../../../components/Utils";


import { getBillings } from '../../../STORE/MiddleWare/Billing';
import { UseBillings } from '../../../STORE/Selector/Billing';


const BillingSummary = () => {
  const dispatch = useDispatch();
  // State
  const [billingData, setBillingData] = useState([]);
  const [show, setShow] = useState(false);
  const [clientsShow, setClientsShow] = useState(false);
  const [clientType, setClientType] = useState('new');
  const [rowValue, setRowValue] = useState({});

  // Custom Selector Hooks
  const billings = UseBillings();

  useEffect(() => {
    dispatch(getBillings());
  }, []);

  useEffect(() => {
    setBillingData(billings?.billing);
  }, [billings]);

  const onRowClickHandler = (e, value) => {
    if (e.target.classList.contains('client-info')) return;
    setShow(true);
    setClientsShow(false);
    setClientType('existing');
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setClientsShow(false);
    setClientType('new');
    setRowValue({});
  };

  const onClientClickHandler = (id) => {
    setShow(false);
    setClientsShow(true);
    setClientType('readonly');
    setRowValue({
      _id: id,
    });
  };

  const addNewBilling = {
    modalId: 'showModal',
    label: 'New Billing',
    onClick: onNewClickHandler,
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: '' }),
      render(text, record) {
        return <div>{text ? moment(record.date).utc().format('DD/MM/YYYY') : null}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'Period',
      align: 'center',
      width: '90px',
      onCell: () => ({ className: 'text-left ' }),
      render(text) {
        return <div>{text}</div>;
      },
      fixed: 'left',
      filters: frameFilterArray(billingData, 'period'),
      onFilter: (value, record) => record.period === value,
      sorter: (a, b) => a.period.localeCompare(b.period),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' client-info' }),
      render(text, row) {
        return (
          <Button
            type="link"
            className="client-info-button client-info"
            onClick={() => onClientClickHandler(row.clientId)}
          >
            <p className="client-info">{text}</p>
          </Button>
        );
      },
      filters: frameFilterArray(billingData, 'clientName'),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Particulars',
      dataIndex: 'particulars',
      key: 'particulars',
      align: 'center',
      width: '120px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Bill Raised',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Debit' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Bill Collected',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Credit' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Discount',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '60px',
      onCell: () => ({ className: ' ' }),
      render(text, record) {
        return <div>{text === 'Discount' ? record.amount : null}</div>;
      },
    },
    {
      title: 'Mode of Payment',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(billingData, 'modeOfPayment'),
      onFilter: (value, record) => record.modeOfPayment === value,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
  ];
  return (
    <><div className="row mt-4 client-wrapper">
      <SearchTable
        columns={columns}
        data={billingData}
        searchItems={['clientName', 'modeOfPayment', 'particulars', 'type', 'task', 'period', 'date']}
        addNewPopup={addNewBilling}
        onRowClickHandler={onRowClickHandler}
      />
    </div>
      <DrawerBilling show={show} setShow={setShow} clientType={clientType} rowValue={rowValue} />
      <DrawerClients show={clientsShow} setShow={setClientsShow} clientType={'readonly'} rowValue={rowValue} id={rowValue.clientId} /> </>
  )
};
export default BillingSummary;