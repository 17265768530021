/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Drawer, Form, Row, Steps, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { formatDatetoDb, ShowMessage } from '../../components/Utils';

import InputContainer from '../../components/InputContainer/InputContainer';
import DrawerButtons from './DrawerButtons';
import { createItTask, getItTasks, updateItTask } from '../../STORE/MiddleWare/ItTask';
import { UseViewFinancialYear } from '../../STORE/Selector/Utils';
import { IsSuperAdmin } from '../../STORE/Selector/Auth';

// CSS
import '../../css/GstTask.scss';
import ClientCard from '../clients/ClientCard';
import BankDetails from './BankDetails';


const initData = {
  feedbackDescription: '',
  remainderDate: '',
  documentList: [],
  gstPayer: false,
  receivedGstReturns: false,
  deductions: [],
  movableAndImmovable: [],
  shareTrading: [],
  others: [],
  dataCollectedDate: '',
  itrType: '',
  tax: '',
  refund: '',
  statementPreparedDate: '',
  fee: '',
  penalty: '',
  interest: '',
  totalItPayment: '',
  taxCollected: '',
  feeCollected: '',
  collectionDate: '',
  modeOfPayment: '',
  downloadPaidChallan: '',
  paidDate: '',
  acknowledgementNumber: '',
  itFiledDate: '',
  aadhaarLinkedMobileNumber: '',
  itrCopyDownload: '',
  eVerifiedDate: '',
  serialNumber: '',
  printingDate: '',
  fileDistributedTo: '',
  distributionDate: '',
  refundAmount: '',
  refundCreditedDate: '',
  refundInterest: '',
  receivedBankAccountNumber: '',
  receivedBankIFSC: '',
  nextStep: false,
};

// eslint-disable-next-line react/prop-types
const DrawerItTask = ({ show, setShow, userType, rowValue, setRowValue }) => {
  const dispatch = useDispatch();
  const clipboardRef = useRef();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [formErrors, setFormErrors] = useState({});
  const [currentStatus, setCurrentStatus] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [dataInput, setDataInput] = useState({});
  const [bankStatement, setBankStatement] = useState([]);
  const [loanStatement, setLoanStatement] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [movableAndImmovable, setMovableAndImmovable] = useState([]);
  const [shareTrading, setShareTrading] = useState([]);
  const [others, setOthers] = useState([]);

  const CurrentFinancialYear = UseViewFinancialYear();
  const isSuperAdmin = IsSuperAdmin();

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(userType === 'new' ? 'edit' : 'view');

    if (rowValue?._id) {
      setDataInput({ ...rowValue });
      setCurrentStatus(rowValue?.step);
      setSelectedStatus(rowValue?.step);
      const bankStatements = (rowValue?.documentList || []).filter((item) => ['SB', 'CA', 'Others'].includes(item.type));

      const loanStatements = (rowValue?.documentList || []).filter((item) => ['OD', 'CC', 'Term Loan', 'Vehicle Loan', 'Housing Loan', 'Business Loan', 'Others Loan'].includes(item.type));

      setBankStatement(bankStatements.length > 0 ? JSON.parse(JSON.stringify(bankStatements)) : []);
      setLoanStatement(loanStatements.length > 0 ? JSON.parse(JSON.stringify(loanStatements)) : []);
      setDeductions(rowValue?.deductions?.length > 0 ? JSON.parse(JSON.stringify(rowValue?.deductions)) : []);
      setMovableAndImmovable(rowValue?.movableAndImmovable?.length > 0 ? JSON.parse(JSON.stringify(rowValue?.movableAndImmovable)) : []);
      setShareTrading(rowValue?.shareTrading?.length > 0 ? JSON.parse(JSON.stringify(rowValue?.shareTrading)) : []);
      setOthers(rowValue?.others?.length > 0 ? JSON.parse(JSON.stringify(rowValue?.others)) : []);
    } else {
      setDataInput(initData);
      setBankStatement([]);
      setLoanStatement([]);
    }
  }, [show, userType, rowValue]);

  const handleOnChange = (value) => {
    if (currentStatus < value) return;
    setSelectedStatus(value);
  };

  const inputChangeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (value || checked) {
      let updatedErrors = {};
      if (name === "tax" || name === "refund") {
        updatedErrors = {
          tax: "",
          refund: ""
        }
      }
      setFormErrors({ ...formErrors, [name]: '', ...updatedErrors });
    }
    if (name === "downloadPaidChallan" || name === "itrCopyDownload") {
      setDataInput({ ...dataInput, [name]: checked });
    } else {
      setDataInput({ ...dataInput, [name]: value });
    }
  };

  const deductionChangeHandler = (e, index) => {
    const { value } = e.target;
    const updatedDeduction = [...deductions];
    updatedDeduction[index] = value;
    setDeductions(updatedDeduction);
  };

  const propertyChangeHandler = (e, index) => {
    const { value } = e.target;
    const updatedProperty = [...movableAndImmovable];
    updatedProperty[index] = value;
    setMovableAndImmovable(updatedProperty);
  };

  const shareChangeHandler = (e, index) => {
    const { value } = e.target;
    const updatedShareTrading = [...shareTrading];
    updatedShareTrading[index] = value;
    setShareTrading(updatedShareTrading);
  };

  const othersChangeHandler = (e, index) => {
    const { value } = e.target;
    const updatedOthers = [...others];
    updatedOthers[index] = value;
    setOthers(updatedOthers);
  };

  const selectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setDataInput({ ...dataInput, [nameField]: valueObject[valueField] });
  };

  const toggleChangeHandler = (name, value) => {
    setDataInput({ ...dataInput, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (values.step === 0 && !values.dataCollectedDate) {
      errors.dataCollectedDate = 'Please Choose Data Collection Date';
    }
    if (values.step === 1 && !values.itrType) {
      errors.itrType = 'Please Choose ITR Type';
    }
    if (values.step === 1 && ((!values.tax && !values.refund) || (values.tax && values.refund))) {
      errors.tax = 'Please enter either Tax or Refund';
      errors.refund = 'Please enter either Tax or Refund';
    }
    if (values.step === 1 && !values.statementPreparedDate) {
      errors.statementPreparedDate = 'Please Choose Statement Prepared Date';
    }
    if (values.step === 3 && !values.taxCollected) {
      errors.taxCollected = 'Please Enter Tax Collected';
    }
    if (values.step === 3 && !values.feeCollected) {
      errors.feeCollected = 'Please Enter Fee Collected';
    }
    if (values.step === 3 && !values.collectionDate) {
      errors.collectionDate = 'Please Choose Collection Date';
    }
    if (values.step === 3 && !values.modeOfPayment) {
      errors.modeOfPayment = 'Please Enter Mode of Payment';
    }
    if (values.step === 4 && !values.downloadPaidChallan) {
      errors.downloadPaidChallan = 'Please tick the box';
    }
    if (values.step === 4 && !values.paidDate) {
      errors.paidDate = 'Please Choose Paid Date';
    }
    if (values.step === 5 && !values.acknowledgementNumber) {
      errors.acknowledgementNumber = 'Please Enter Acknowledgement Number';
    }
    if (values.step === 5 && !values.itFiledDate) {
      errors.itFiledDate = 'Please Enter IT Filed Date';
    }
    if (values.step === 6 && !values.aadhaarLinkedMobileNumber) {
      errors.aadhaarLinkedMobileNumber = 'Please Enter Aadhaar Linked Mobile Number';
    }
    if (values.step === 6 && !values.itrCopyDownload) {
      errors.itrCopyDownload = 'Please tick box';
    }
    if (values.step === 6 && !values.eVerifiedDate) {
      errors.eVerifiedDate = 'Please Enter E-Verified Date';
    }
    if (values.step === 7 && !values.printingDate) {
      errors.printingDate = 'Please Choose Printing Date';
    }
    if (values.step === 8 && !values.fileDistributedTo) {
      errors.fileDistributedTo = 'Please Enter File Distributed To';
    }
    if (values.step === 8 && !values.distributionDate) {
      errors.distributionDate = 'Please Enter Distribution Date';
    }
    if (values.step === 9 && !values.receivedBankAccountNumber) {
      errors.receivedBankAccountNumber = 'Please Enter Received Bank Account Number';
    }
    if (values.step === 9 && !values.receivedBankIFSC) {
      errors.receivedBankIFSC = 'Please Enter Received Bank IFSC';
    }
    if (values.step === 9 && !values.refundCreditedDate) {
      errors.refundCreditedDate = 'Please Enter Refund Credited Date';
    }

    return errors;
  };

  const validateOnCurrentStep = (values) => {
    const errors = {};

    if (values.step === 0 && values.feedbackDescription && !values.remainderDate) {
      errors.dataCollectedDate = 'Please Enter Reminder Date';
    }

    return errors;
  };

  const addDeductionHandler = () => {
    setDeductions([...deductions, '']);
  };
  const addPropertyHandler = () => {
    setMovableAndImmovable([...movableAndImmovable, '']);
  };
  const addShareHandler = () => {
    setShareTrading([...shareTrading, '']);
  };
  const addOthersHandler = () => {
    setOthers([...others, '']);
  };

  const deleteDeductionHandler = (index) => {
    setDeductions([...deductions.toSpliced(index, 1)]);
  };

  const deletePropertyHandler = (index) => {
    setMovableAndImmovable([...movableAndImmovable.toSpliced(index, 1)]);
  };

  const deleteShareHandler = (index) => {
    setShareTrading([...shareTrading.toSpliced(index, 1)]);
  };

  const deleteOthersHandler = (index) => {
    setOthers([...others.toSpliced(index, 1)]);
  };

  const ShareToWhatsappHandler = () => {
    window.open(`whatsapp://send?phone=${dataInput?.client?.glContactMobileNo}&text=${encodeURI(clipboardRef?.current.innerText)}`);
    ShowMessage('success', `Shared to Whatsapp Successfully`);
  };

  const onSubmitHandler = (nextStep = false) => {
    if (nextStep) {
      const fieldErrors = validate(dataInput);

      if (!_.isEmpty(fieldErrors)) {
        setFormErrors(fieldErrors);
        return;
      }
    } else {
      const fieldErrors = validateOnCurrentStep(dataInput);
      if (!_.isEmpty(fieldErrors)) {
        setFormErrors(fieldErrors);
        return;
      }
    }
    const documentList = [
      ...bankStatement,
      ...loanStatement,
    ];

    const ItTaskData = {
      feedbackDescription: dataInput.feedbackDescription,
      remainderDate: formatDatetoDb(dataInput.remainderDate),
      gstPayer: dataInput.gstPayer,
      receivedGstReturns: dataInput.receivedGstReturns,
      documentList,
      deductions,
      movableAndImmovable,
      shareTrading,
      others,
      dataCollectedDate: formatDatetoDb(dataInput.dataCollectedDate),
      itrType: dataInput.itrType,
      tax: dataInput.tax,
      refund: dataInput.refund,
      statementPreparedDate: dataInput.statementPreparedDate,
      fee: dataInput.fee,
      penalty: dataInput.penalty,
      interest: dataInput.interest,
      totalItPayment: (Number(dataInput?.taxValue) || 0) + (Number(dataInput?.penalty) || 0) + (Number(dataInput?.interest) || 0) + (Number(dataInput?.fee) || 0),
      taxCollected: dataInput.taxCollected,
      feeCollected: dataInput.feeCollected,
      collectionDate: formatDatetoDb(dataInput.collectionDate),
      modeOfPayment: dataInput.modeOfPayment,
      downloadPaidChallan: dataInput.downloadPaidChallan,
      paidDate: dataInput.paidDate,
      acknowledgementNumber: dataInput.acknowledgementNumber,
      itFiledDate: formatDatetoDb(dataInput.itFiledDate),
      aadhaarLinkedMobileNumber: dataInput.aadhaarLinkedMobileNumber,
      itrCopyDownload: dataInput.itrCopyDownload,
      eVerifiedDate: formatDatetoDb(dataInput.eVerifiedDate),
      serialNumber: dataInput.serialNumber,
      printingDate: formatDatetoDb(dataInput.printingDate),
      fileDistributedTo: dataInput.fileDistributedTo,
      distributionDate: formatDatetoDb(dataInput.distributionDate),
      refundAmount: dataInput.refundAmount,
      refundCreditedDate: formatDatetoDb(dataInput.refundCreditedDate),
      refundInterest: dataInput.refundInterest,
      receivedBankAccountNumber: dataInput.receivedBankAccountNumber,
      receivedBankIFSC: dataInput.receivedBankIFSC,
      step: dataInput.step,
      nextStep,
    };

    if (userType === 'existing') {
      ItTaskData._id = dataInput._id;
      dispatch(updateItTask(ItTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'IT Task Updated Successfully');
          dispatch(getItTasks({ financialYear: CurrentFinancialYear }));
          setRowValue(res?.payload?.ItTask);
        }
      });
    } else {
      dispatch(createItTask(ItTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'IT Task Created Successfully');
          dispatch(getItTasks({ financialYear: CurrentFinancialYear }));
          onClose();
        }
      });
    }
  };

  const CopyToClipboardHandler = () => {
    navigator.clipboard.writeText(clipboardRef?.current.innerHTML);
    ShowMessage('success', `Copied to Clipboard Successfully`);
  };

  const items = [
    {
      title: 'Contact the Client',
      description: (
        <>
          {(selectedStatus === 0 || currentStatus === 0) && (
            <div className="d-flex flex-wrap">
              <div className="d-block w-100">
                {dataInput?.feedback?.map((item) => (
                  <div key={item?.reminderDate} className="feedback-card">
                    <div>
                      <p>{item?.notes}</p>
                      {item?.reminderDate ? <p className="reminder-content">{moment(item?.reminderDate).format('YYYY-MM-DD')}</p> : <></>}
                    </div>
                  </div>
                ))}
              </div>
              {currentStatus === 0 ?
                <>
                  <InputContainer
                    containerClassName="pe-3 pb-3 col-md-12"
                    label="Feedback"
                    type="textarea"
                    id="feedbackDescription"
                    name="feedbackDescription"
                    value={dataInput?.feedbackDescription}
                    error={formErrors?.feedbackDescription}
                    onChange={inputChangeHandler}
                    placeholder="Please Enter Feedback"
                    disabled={currentStatus !== 0 && !isSuperAdmin}
                  />
                  <div className="col-md-12">
                    <InputContainer
                      containerClassName="pe-3 pb-3 col-md-4"
                      label="Remainder Date"
                      format="dd/mm/yy"
                      type="prime-date"
                      picker="date"
                      id="remainderDate"
                      name="remainderDate"
                      value={dataInput?.remainderDate}
                      error={formErrors?.remainderDate}
                      onChange={inputChangeHandler}
                      placeholder="Please Choose Remainder Date"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                  </div>
                </>
                : <></>}
              <div className="d-flex flex-wrap">
                <div className="col-md-12">
                  <p>Document List</p>
                </div>
                <InputContainer
                  containerClassName="pe-3 pb-3 col-md-6"
                  label="GST Payer"
                  type="toggle"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  id="gstPayer"
                  name="gstPayer"
                  value={dataInput?.gstPayer}
                  onChange={(value) => toggleChangeHandler('gstPayer', value)}
                  disabled={currentStatus !== 0 && !isSuperAdmin}
                />
                <InputContainer
                  containerClassName="pe-3 pb-3 col-md-6"
                  label="Received GST Returns"
                  type="toggle"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  id="receivedGstReturns"
                  name="receivedGstReturns"
                  value={dataInput?.receivedGstReturns}
                  onChange={(value) => toggleChangeHandler('receivedGstReturns', value)}
                  disabled={currentStatus !== 0 && !isSuperAdmin}
                />
                <Tabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: '1',
                      label: 'Bank Statement',
                      children: (
                        <BankDetails
                          statement={bankStatement}
                          setStatement={setBankStatement}
                          mode={mode}
                          userType={userType}
                          currentStatus={currentStatus}
                          typeOptions={bankTypeOptions}
                          isSuperAdmin={isSuperAdmin}
                        />
                      ),
                    },
                    {
                      key: '2',
                      label: 'Loan Statement',
                      children: (
                        <BankDetails
                          statement={loanStatement}
                          setStatement={setLoanStatement}
                          mode={mode}
                          userType={userType}
                          currentStatus={currentStatus}
                          typeOptions={loanTypeOptions}
                          isSuperAdmin={isSuperAdmin}
                        />
                      ),
                    },
                  ]}
                />
                <InputContainer containerClassName="col-md-12" label={'Deductions'} type="" />
                {deductions.map((deduction, index) => (
                  <>
                    <InputContainer
                      key={index}
                      containerClassName="pe-3 pb-3 col-md-4"
                      type="text"
                      id={`deduction-${index}`}
                      name="deduction"
                      value={deduction}
                      error={formErrors?.deduction}
                      onChange={(e) => {
                        deductionChangeHandler(e, index);
                      }}
                      placeholder="Please Enter Deductions"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                    <div className="col-md-2 d-flex align-items-top">
                      {currentStatus === 0 || isSuperAdmin ?
                        <Button type="primary" danger className="mt-2" onClick={() => deleteDeductionHandler(index)}>
                          Remove
                        </Button>
                        : <></>}
                    </div>
                  </>
                ))}
                <div className="col-md-12 d-flex align-items-center">
                  {currentStatus === 0 || isSuperAdmin ?
                    <Button type="primary" className="mt-2 mb-4" onClick={addDeductionHandler}>
                      Add Deduction
                    </Button>
                    : <></>}
                </div>
                <InputContainer
                  containerClassName="col-md-12"
                  label={'Movable and Immovable Property Details'}
                  type=""
                />
                {movableAndImmovable.map((property, index) => (
                  <>
                    <InputContainer
                      containerClassName="pe-3 pb-3 col-md-4"
                      type="text"
                      id={`property-${index}`}
                      name="property"
                      value={property}
                      error={formErrors?.property}
                      onChange={(e) => {
                        propertyChangeHandler(e, index);
                      }}
                      placeholder="Please Enter Property Details"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                    <div className="col-md-2 d-flex align-items-top">
                      {currentStatus === 0 || isSuperAdmin ?
                        <Button type="primary" danger className="mt-2" onClick={() => deletePropertyHandler(index)}>
                          Remove
                        </Button>
                        : <></>}
                    </div>
                  </>
                ))}
                <div className="col-md-12 d-flex align-items-center">
                  {currentStatus === 0 || isSuperAdmin ?
                    <Button type="primary" className="mt-2 mb-4" onClick={addPropertyHandler}>
                      Add Movable and Immovable Property
                    </Button>
                    : <></>}
                </div>
                <InputContainer containerClassName="col-md-12" label={'Share Trading & SIP Detail'} type="" />
                {shareTrading.map((shareTradingDetails, index) => (
                  <>
                    <InputContainer
                      containerClassName="pe-3 pb-3 col-md-4"
                      type="text"
                      id={`shareTradingDetails-${index}`}
                      name="shareTradingDetails"
                      value={shareTradingDetails}
                      error={formErrors?.shareTradingDetails}
                      onChange={(e) => shareChangeHandler(e, index)}
                      placeholder="Please Enter Share Trading & SIP Detail"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                    <div className="col-md-2 d-flex align-items-top">
                      {currentStatus === 0 || isSuperAdmin ?
                        <Button type="primary" danger className="mt-2" onClick={() => deleteShareHandler(index)}>
                          Remove
                        </Button>
                        : <></>}
                    </div>
                  </>
                ))}
                <div className="col-md-12 d-flex align-items-center">
                  {currentStatus === 0 || isSuperAdmin ?
                    <Button type="primary" className="mt-2 mb-4" onClick={addShareHandler}>
                      Add Share Trading & SIP Detail
                    </Button>
                    : <></>}
                </div>
                <InputContainer containerClassName="col-md-12" label={'Others'} type="" />
                {others.map((other, index) => (
                  <>
                    <InputContainer
                      containerClassName="pe-3 pb-3 col-md-4"
                      type="text"
                      id={`other-${index}`}
                      name="other"
                      value={other}
                      onChange={(e) => othersChangeHandler(e, index)}
                      placeholder="Please Enter Other details if any"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                    <div className="col-md-2 d-flex align-items-top">
                      {currentStatus === 0 || isSuperAdmin ?
                        <Button type="primary" danger className="mt-2" onClick={() => deleteOthersHandler(index)}>
                          Remove
                        </Button>
                        : <></>}
                    </div>
                  </>
                ))}
                <div className="col-md-12 d-flex align-items-center">
                  {currentStatus === 0 || isSuperAdmin ?
                    <Button type="primary" className="mt-2 mb-4" onClick={addOthersHandler}>
                      Add Others
                    </Button>
                    : <></>}
                </div>

                <InputContainer
                  containerClassName="pe-3 pb-3 col-md-3"
                  label="Data Collected Date"
                  format="dd/mm/yy"
                  type="prime-date"
                  picker="date"
                  id="dataCollectedDate"
                  name="dataCollectedDate"
                  value={dataInput?.dataCollectedDate}
                  error={formErrors?.dataCollectedDate}
                  onChange={inputChangeHandler}
                  placeholder="Please Choose Data Collected Date"
                  disabled={currentStatus !== 0 && !isSuperAdmin}
                  required
                />
              </div >
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Statement Preparation',
      description: (
        <>
          {(selectedStatus === 1 || currentStatus === 1) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-12"
                inputClassName="d-flex"
                label="ITR Type"
                type="radio"
                id="itrType"
                name="itrType"
                value={dataInput?.itrType}
                error={formErrors?.itrType}
                onChange={inputChangeHandler}
                disabled={currentStatus !== 1 && !isSuperAdmin}
                options={ITROptions}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tax"
                type="number"
                id="tax"
                name="tax"
                value={dataInput?.tax}
                error={formErrors?.tax}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax"
                disabled={currentStatus !== 1 && !isSuperAdmin}
                required={!dataInput.refund ? "required" : ""}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Refund"
                type="number"
                id="refund"
                name="refund"
                value={dataInput?.refund}
                error={formErrors?.refund}
                onChange={inputChangeHandler}
                placeholder="Please Enter Refund"
                disabled={currentStatus !== 1 && !isSuperAdmin}
                required={!dataInput.tax ? "required" : ""}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Statement Prepared Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="statementPreparedDate"
                name="statementPreparedDate"
                value={dataInput?.statementPreparedDate}
                error={formErrors?.statementPreparedDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Statement Prepared Date"
                disabled={currentStatus !== 1 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Inform the Payment',
      description: (
        <>
          {(selectedStatus === 2 || currentStatus === 2) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tax Value"
                type="text"
                id="tax"
                name="tax"
                value={dataInput?.tax}
                error={formErrors?.tax}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Value"
                disabled
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Fee"
                type="number"
                id="fee"
                name="fee"
                value={dataInput?.fee}
                error={formErrors?.fee}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Penalty"
                type="number"
                id="penalty"
                name="penalty"
                value={dataInput?.penalty}
                error={formErrors?.penalty}
                onChange={inputChangeHandler}
                placeholder="Please Enter Penalty"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Interest"
                type="number"
                id="interest"
                name="interest"
                value={dataInput?.interest}
                error={formErrors?.interest}
                onChange={inputChangeHandler}
                placeholder="Please Enter Interest"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Total IT Payment"
                type="number"
                id="totalItPayment"
                name="totalItPayment"
                value={
                  (Number(dataInput?.taxValue) || 0) +
                  (Number(dataInput?.penalty) || 0) +
                  (Number(dataInput?.interest) || 0) +
                  (Number(dataInput?.fee) || 0)
                }
                error={formErrors?.totalItPayment}
                onChange={inputChangeHandler}
                placeholder="Please Enter Total IT Payment"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
              <div className="pe-3 pb-3 w-100">
                <div className="clipboard-section">
                  <div ref={clipboardRef}>
                    <p>
                      Hi {dataInput?.client?.clientName?.toUpperCase()}, <br />
                      &emsp;&emsp;Your IT return for the financial year **Filing year** has been filed.
                      <br />
                      &emsp;&emsp;Income Tax Payable is Rs. {(Number(dataInput?.taxValue) || 0) +
                        (Number(dataInput?.penalty) || 0) +
                        (Number(dataInput?.interest) || 0) +
                        (Number(dataInput?.fee) || 0)}
                      <br />
                      &emsp;&emsp;Fee for Income Tax return filing is Rs. {dataInput?.fee}
                      <br />
                      {dataInput?.penalty && (
                        <>
                          &emsp;&emsp;Penalty Rs. {dataInput?.penalty}
                          <br />
                        </>
                      )}
                      {dataInput?.interest && (
                        <>
                          &emsp;&emsp;Interest Rs. {dataInput?.interest}
                          <br />
                        </>
                      )}
                      &emsp;&emsp;Total amount is Rs. {' '}
                      {(Number(dataInput?.taxValue) || 0) +
                        (Number(dataInput?.penalty) || 0) +
                        (Number(dataInput?.interest) || 0) +
                        (Number(dataInput?.fee) || 0)}
                      <br />
                      &emsp;&emsp;Kindly, make the payment shortly to avoid the penality charges.
                      <br />
                      <br />- Gems Clip Audit Services
                    </p>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="clipboard-icon-watsapp"
                      onClick={ShareToWhatsappHandler}
                    />
                    <FontAwesomeIcon icon={faCopy} className="clipboard-icon-copy" onClick={CopyToClipboardHandler} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Collection of the Payment',
      description: (
        <>
          {(selectedStatus === 3 || currentStatus === 3) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tax Collected"
                type="number"
                id="taxCollected"
                name="taxCollected"
                value={dataInput?.taxCollected}
                error={formErrors?.taxCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Collected"
                disabled={currentStatus !== 3 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Fee Collected"
                type="number"
                id="feeCollected"
                name="feeCollected"
                value={dataInput?.feeCollected}
                error={formErrors?.feeCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee Collected"
                disabled={currentStatus !== 3 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Collection Date"
                format="dd/mm/yy"
                picker="date"
                type="prime-date"
                id="collectionDate"
                name="collectionDate"
                value={dataInput?.collectionDate}
                error={formErrors?.collectionDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Collection Date"
                disabled={currentStatus !== 3 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Mode of Payment"
                type="select"
                id="modeOfPayment"
                name="modeOfPayment"
                value={dataInput?.modeOfPayment}
                error={formErrors?.modeOfPayment}
                onChange={selectChangeHandler.bind(this, 'modeOfPayment', 'value')}
                options={modeOfPayment}
                optionsValueKey="value"
                optionsLabelKey="label"
                disabled={currentStatus !== 3 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Tax Payment',
      description: (
        <>
          {(selectedStatus === 4 || currentStatus === 4) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Download Paid Challan"
                type="checkbox"
                id="downloadPaidChallan"
                name="downloadPaidChallan"
                value={dataInput?.downloadPaidChallan}
                error={formErrors?.downloadPaidChallan}
                onChange={inputChangeHandler}
                disabled={currentStatus !== 4 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Paid Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="paidDate"
                name="paidDate"
                value={dataInput?.paidDate}
                error={formErrors?.paidDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Paid Date"
                disabled={currentStatus !== 4 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Income Tax Filling',
      description: (
        <>
          {(selectedStatus === 5 || currentStatus === 5) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Acknowledgement Number"
                type="number"
                id="acknowledgementNumber"
                name="acknowledgementNumber"
                value={dataInput?.acknowledgementNumber}
                error={formErrors?.acknowledgementNumber}
                onChange={inputChangeHandler}
                placeholder="Please Enter Acknowledgement Number"
                disabled={currentStatus !== 5 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="IT Filed Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="itFiledDate"
                name="itFiledDate"
                value={dataInput?.itFiledDate}
                error={formErrors?.itFiledDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose IT Filed Date"
                disabled={currentStatus !== 5 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'E-verification',
      description: (
        <>
          {(selectedStatus === 6 || currentStatus === 6) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Aadhaar Linked Mobile Number"
                type="number"
                id="aadhaarLinkedMobileNumber"
                name="aadhaarLinkedMobileNumber"
                value={dataInput?.aadhaarLinkedMobileNumber}
                error={formErrors?.aadhaarLinkedMobileNumber}
                onChange={inputChangeHandler}
                placeholder="Please Enter Aadhaar Linked Mobile Number"
                disabled={currentStatus !== 6 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="ITR Copy Download"
                type="checkbox"
                id="itrCopyDownload"
                name="itrCopyDownload"
                value={dataInput?.itrCopyDownload}
                error={formErrors?.itrCopyDownload}
                onChange={inputChangeHandler}
                disabled={currentStatus !== 6 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="E-Verified Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="eVerifiedDate"
                name="eVerifiedDate"
                value={dataInput?.eVerifiedDate}
                error={formErrors?.eVerifiedDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose E-Verified Date"
                disabled={currentStatus !== 6 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Print',
      description: (
        <>
          {(selectedStatus === 7 || currentStatus === 7) && (
            <div className="d-flex flex-wrap">
              {/* <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Serial Number"
                type="text"
                id="serialNumber"
                name="serialNumber"
                value={dataInput?.serialNumber}
                error={formErrors?.serialNumber}
                onChange={inputChangeHandler}
                placeholder="Please Enter Serial Number"
                disabled={currentStatus !== 7}
              /> */}
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Printing Date"
                type="prime-date"
                format="dd/mm/yy"
                picker="date"
                id="printingDate"
                name="printingDate"
                value={dataInput?.printingDate}
                error={formErrors?.printingDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Printing Date"
                disabled={currentStatus !== 7 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Distribution',
      description: (
        <>
          {(selectedStatus === 8 || currentStatus === 8) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="File Distributed To"
                type="text"
                id="fileDistributedTo"
                name="fileDistributedTo"
                value={dataInput?.fileDistributedTo}
                error={formErrors?.fileDistributedTo}
                onChange={inputChangeHandler}
                placeholder="Please Enter File Distributed To"
                disabled={currentStatus !== 8 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Distribution Date"
                type="prime-date"
                format="dd/mm/yy"
                picker="date"
                id="distributionDate"
                name="distributionDate"
                value={dataInput?.distributionDate}
                error={formErrors?.distributionDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Distribution Date"
                disabled={currentStatus !== 8 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },
  ];

  if (dataInput?.refund) {
    items.push({
      title: 'Refund Follow Up',
      description: (
        <>
          {(selectedStatus === 9 || currentStatus === 9) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Refund"
                type="text"
                id="refund"
                name="refund"
                value={dataInput?.refund}
                // error={formErrors?.refund}
                // onChange={inputChangeHandler}
                // placeholder="Please Enter Refund"
                disabled
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Refund Interest"
                type="text"
                id="refundInterest"
                name="refundInterest"
                value={dataInput?.refundInterest}
                error={formErrors?.refundInterest}
                onChange={inputChangeHandler}
                placeholder="Please Enter Refund Interest"
                disabled={currentStatus !== 9 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Refund Amount"
                type="number"
                id="refundAmount"
                name="refundAmount"
                value={dataInput?.refundAmount}
                error={formErrors?.refundAmount}
                onChange={inputChangeHandler}
                placeholder="Please Enter Refund Amount"
                disabled={currentStatus !== 9 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Received Bank Account Number"
                type="text"
                id="receivedBankAccountNumber"
                name="receivedBankAccountNumber"
                value={dataInput?.receivedBankAccountNumber}
                error={formErrors?.receivedBankAccountNumber}
                onChange={inputChangeHandler}
                placeholder="Please Enter Received Bank Account Number"
                disabled={currentStatus !== 9 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Received Bank IFSC"
                type="text"
                id="receivedBankIFSC"
                name="receivedBankIFSC"
                value={dataInput?.receivedBankIFSC}
                error={formErrors?.receivedBankIFSC}
                onChange={inputChangeHandler}
                placeholder="Please Enter Received Bank IFSC"
                disabled={currentStatus !== 9 && !isSuperAdmin}
                required
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Refund Credited Date"
                type="prime-date"
                format="dd/mm/yy"
                picker="date"
                id="refundCreditedDate"
                name="refundCreditedDate"
                value={dataInput?.refundCreditedDate}
                error={formErrors?.refundCreditedDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Refund Credited Date"
                disabled={currentStatus !== 9 && !isSuperAdmin}
                required
              />
            </div>
          )}
        </>
      ),
    },)
  }

  return (
    <Drawer
      title={userType === 'new' ? 'Create a New IT Task' : 'Update IT Task'}
      width={selectedStatus === 0 || currentStatus === 0 ? '75%' : '40%'}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <DrawerButtons
          showSave={currentStatus < 10 || isSuperAdmin}
          showProceed={currentStatus < 10}
          onClose={onClose}
          onSubmitHandler={onSubmitHandler}
        />
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <div className="mb-4 w-100">
            <ClientCard data={dataInput.client} pageName="IT Tasks">
              <div className="text-right">
                <p className='custom-client'>{CurrentFinancialYear || ''}</p>
              </div>
            </ClientCard>
          </div>
          <Steps current={currentStatus} onChange={handleOnChange} direction="vertical" percent={50} items={items} />
        </Row>
      </Form>
    </Drawer >
  );
};
export default DrawerItTask;

const ITROptions = [
  { label: 'ITR Regular', value: 'ITR Regular' },
  { label: 'Section 44AD', value: 'Section 44AD' },
  { label: 'Section 44AB', value: 'Section 44AB' },
];
const modeOfPayment = [
  { label: 'Cash', value: 'Cash' },
  { label: 'UPI', value: 'UPI' },
  { label: 'Bank Transfer', value: 'Bank Transfer' },
];

const loanTypeOptions = [
  { label: 'OD', value: 'OD' },
  { label: 'CC', value: 'CC' },
  { label: 'Term Loan', value: 'Term Loan' },
  { label: 'Vehicle Loan', value: 'Vehicle Loan' },
  { label: 'Housing Loan', value: 'Housing Loan' },
  { label: 'Business Loan', value: 'Business Loan' },
  { label: 'Others', value: 'Others Loan' },
];
const bankTypeOptions = [
  { label: 'SB', value: 'SB' },
  { label: 'CA', value: 'CA' },
  { label: 'Others', value: 'Others' },
];
