import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// CSS
import '../css/Dscs.scss';
import DrawerDscs from '../sections/dscs/DrawerDscs';
import SearchTable from '../components/Table/SearchTable';
import { frameFilterArray } from '../components/Utils';

import { UseDscs } from '../STORE/Selector/Dsc';
import { getDscs } from '../STORE/MiddleWare/Dscs';

const Dsc = () => {
  const dispatch = useDispatch();
  // State
  const [dscsData, setDscsData] = useState([]);
  const [show, setShow] = useState(false);
  const [drawerType, setDrawerType] = useState('new');
  const [rowValue, setRowValue] = useState({});

  // Custom Selector Hooks
  const dscs = UseDscs();

  useEffect(() => {
    dispatch(getDscs());
  }, []);

  useEffect(() => {
    setDscsData(dscs?.dsc);
  }, [dscs]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setDrawerType('existing');
    setRowValue(value);
  };

  const onNewClickHandler = () => {
    setShow(true);
    setDrawerType('new');
    setRowValue({});
  };

  const addNewDsc = {
    modalId: 'showModal',
    label: 'New DSC',
    onClick: onNewClickHandler,
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '250px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(dscsData, 'clientName'),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(dscsData, 'companyName'),
      onFilter: (value, record) => record.companyName === value,
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'validTo',
      key: 'validTo',
      align: 'center',
      width: '200px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text ? moment(text).format('DD/MM/YYYY') : null}</div>;
      },
    },
    {
      title: 'DSC Type',
      dataIndex: 'dscType',
      key: 'dscType',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
      filters: frameFilterArray(dscsData, 'dscType'),
      onFilter: (value, record) => record.dscType === value,
      sorter: (a, b) => a.dscType.localeCompare(b.dscType),
    },
    {
      title: 'DSC Payment Received On',
      dataIndex: 'dscPaymentReceivedon',
      key: 'dscPaymentReceivedon',
      align: 'center',
      width: '170px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text ? moment(text).format('DD/MM/YYYY') : ""}</div>;
      },
      sorter: (a, b) => a.dscPaymentReceivedon.localeCompare(b.dscPaymentReceivedon),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '150px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div className={StatusClassNameHandler(text)}>{text}</div>;
      },
      filters: frameFilterArray(dscsData, 'status'),
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
  ];

  return (
    <>
      <Helmet>
        <title> DSC's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4 dsc-wrapper">
        <SearchTable
          columns={columns}
          data={dscsData}
          searchItems={['clientName', 'clientId']}
          addNewPopup={addNewDsc}
          onRowClickHandler={onRowClickHandler}
        />
      </div>

      <DrawerDscs show={show} setShow={setShow} drawerType={drawerType} rowValue={rowValue} />
    </>
  );
};

export default Dsc;

const StatusClassNameHandler = (value) => {
  if (value === 'Expired') {
    return 'status-rejected';
  }

  if (value === 'Pending') {
    return 'status-pending';
  }

  if (value) {
    return 'status-completed';
  }
  return null;
};
