/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Form, Row, Steps, Tabs, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import _ from 'lodash';

import { Confirmation, ShowMessage, formatDatetoDb, ordinal } from '../../components/Utils';

import InputContainer from '../../components/InputContainer/InputContainer';
import DrawerButtons from './DrawerButtons';
import { createGstTask, deleteGstTask, getGstTasks, updateGstTask } from '../../STORE/MiddleWare/GstTask';
import { UseViewMonth } from '../../STORE/Selector/Utils';
import { IsSuperAdmin } from '../../STORE/Selector/Auth';

// CSS
import '../../css/GstTask.scss';
import ClientCard from '../clients/ClientCard';
import Bills from './Bills';

const modeOfPayment = [
  { label: 'Cash', value: 'Cash' },
  { label: 'UPI', value: 'UPI' },
  { label: 'Bank Transfer', value: 'Bank Transfer' },
];

const reconciliationOptions = [
  {
    value: 'DataMatching',
    label: 'Data is Matching',
  },
  {
    value: 'DataNotMatching',
    label: 'Data is Not Matching',
  },
];

const initData = {
  id: null,
  feedback: [],
  feedbackDescription: '',
  remainderDate: '',
  nilReturn: '',
  dataCollectedDate: '',
  tallyEntryDate: '',
  acknowledgementNo: '',
  gstr1FilingDate: '',
  gstr2bReconciliation: 'DataMatching',
  gstr2bBillType: '',
  gstPayable: '',
  taxValue: '',
  fee: '',
  penalty: '',
  interest: '',
  totalGstPayment: '',
  taxCollected: '',
  feeCollected: '',
  collectionDate: '',
  modeOfPayment: null,
  gstr3AcknowledgementNo: '',
  gstr3FiledDate: '',
  downloadDate: '',
  clientId: '',
  step: 0,
  nextStep: false,
};

const emptyBill = [
  {
    billNo: '',
    billDate: '',
    gstIn: '',
    partyName: '',
    billValue: '',
    billStatus: '',
    filed: '',
  },
];
// eslint-disable-next-line react/prop-types
const DrawerGstTask = ({ show, setShow, recordType, rowValue, setRowValue }) => {
  const dispatch = useDispatch();
  const clipboardRef = useRef();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('edit');
  const [formErrors, setFormErrors] = useState({});
  const [currentStatus, setCurrentStatus] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [counterParyBills, setCounterParyBills] = useState(emptyBill);
  const [missingBills, setMissingBills] = useState(emptyBill);

  const [dataInput, setDataInput] = useState({});

  const CurrentViewMonth = UseViewMonth();
  const isSuperAdmin = IsSuperAdmin();

  const taskMonth = moment(`${CurrentViewMonth?.month}-${CurrentViewMonth?.year}`, 'MM-YYYY').format('MMMM-YYYY');

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(recordType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setDataInput({
        ...rowValue
      });
      setCurrentStatus(rowValue?.step);
      setSelectedStatus(rowValue?.step);
      setCounterParyBills(JSON.parse(JSON.stringify(rowValue.gstr2bCounterPartyBills)));
      setMissingBills(JSON.parse(JSON.stringify(rowValue.gstr2bMissingBills)));
    } else {
      setDataInput(initData);
      setCounterParyBills(emptyBill);
      setMissingBills(emptyBill);
    }
  }, [show, recordType, rowValue]);

  const handleOnChange = (value) => {
    if (currentStatus < value) return;
    setSelectedStatus(value);

  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }

    setDataInput({ ...dataInput, [name]: value });
  };

  const selectChangeHandler = (nameField, valueField, valueObject) => {
    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    setDataInput({ ...dataInput, [nameField]: valueObject[valueField] });
  };

  const toggleChangeHandler = (name, value) => {
    setDataInput({ ...dataInput, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (values.step === 0 && !values.dataCollectedDate) {
      errors.dataCollectedDate = 'Please choose Data Collected Date';
    }

    if (values.step === 1 && !values.tallyEntryDate) {
      errors.tallyEntryDate = 'Please choose Tally Entry Date';
    }

    if (values.step === 2 && !values.acknowledgementNo) {
      errors.acknowledgementNo = 'Please Enter Acknowledgement No';
    }

    if (values.step === 2 && !values.gstr1FilingDate) {
      errors.gstr1FilingDate = 'Please Choose GSTR 1 Filing Date';
    }

    if (values.step === 3 && !values.gstr2bReconciliation) {
      errors.gstr2bReconciliation = 'Please Choose GSTR 2B Reconciliation';
    }

    if (values.step === 4 && !values.gstPayable) {
      errors.gstPayable = 'Please Enter GST Payable';
    }

    if (values.step === 5 && !values.fee) {
      errors.fee = 'Please Enter Fee';
    }

    if (values.step === 6 && !values.taxCollected) {
      errors.taxCollected = 'Please Enter Tax Collected';
    }

    if (values.step === 6 && !values.feeCollected) {
      errors.feeCollected = 'Please Enter Fee Collected';
    }

    if (values.step === 6 && !values.collectionDate) {
      errors.collectionDate = 'Please Choose Collection Date';
    }

    if (values.step === 6 && !values.modeOfPayment) {
      errors.modeOfPayment = 'Please Choose Mode of Payment';
    }

    if (values.step === 7 && !values.gstr3AcknowledgementNo) {
      errors.gstr3AcknowledgementNo = 'Please Enter GSTR3 Acknowledgement No';
    }

    if (values.step === 7 && !values.gstr3FiledDate) {
      errors.gstr3FiledDate = 'Please choose GSTR3 Filed Date';
    }

    if (values.step === 8 && !values.downloadDate) {
      errors.downloadDate = 'Please choose Download Date';
    }

    return errors;
  };

  const validateOnCurrentStep = (values) => {
    const errors = {};

    if (values.step === 0 && values.feedbackDescription && !values.remainderDate) {
      errors.dataCollectedDate = 'Please Enter Reminder Date';
    }

    return errors;
  };

  const onSubmitHandler = (nextStep = false) => {
    if (nextStep) {
      const fieldErrors = validate(dataInput);
      if (!_.isEmpty(fieldErrors)) {
        setFormErrors(fieldErrors);
        return;
      }

    } else {
      const fieldErrors = validateOnCurrentStep(dataInput);

      if (!_.isEmpty(fieldErrors)) {
        setFormErrors(fieldErrors);
        return;
      }
    }

    const GstTaskData = {
      feedbackDescription: dataInput.feedbackDescription,
      remainderDate: formatDatetoDb(dataInput.remainderDate),
      nilReturn: dataInput.nilReturn,
      dataCollectedDate: formatDatetoDb(dataInput.dataCollectedDate),
      tallyEntryDate: formatDatetoDb(dataInput.tallyEntryDate),
      acknowledgementNo: dataInput.acknowledgementNo,
      gstr1FilingDate: formatDatetoDb(dataInput.gstr1FilingDate),
      gstr2bReconciliation: dataInput.gstr2bReconciliation,
      gstr2bCounterPartyBills: counterParyBills,
      gstr2bMissingBills: missingBills,
      gstPayable: dataInput.gstPayable,
      taxValue: dataInput.gstPayable, // Both Tax Value and GST Payable is same
      fee: dataInput.fee,
      penalty: dataInput.penalty,
      interest: dataInput.interest,
      totalGstPayment: dataInput.totalGstPayment,
      taxCollected: dataInput.taxCollected,
      feeCollected: dataInput.feeCollected,
      collectionDate: formatDatetoDb(dataInput.collectionDate),
      modeOfPayment: dataInput.modeOfPayment,
      gstr3AcknowledgementNo: dataInput.gstr3AcknowledgementNo,
      gstr3FiledDate: formatDatetoDb(dataInput.gstr3FiledDate),
      downloadDate: formatDatetoDb(dataInput.downloadDate),
      step: dataInput.step,
      nextStep,
      clientId: dataInput.clientId,
    };

    if (recordType === 'existing') {
      GstTaskData._id = dataInput._id;
      dispatch(updateGstTask(GstTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'GstTask Updated Successfully');
          setCounterParyBills(emptyBill);
          setMissingBills(emptyBill);
          dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
          setRowValue(res?.payload?.GstTask);
        }
      });
    } else {
      dispatch(createGstTask(GstTaskData)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'GstTask Created Successfully');
          setCounterParyBills(emptyBill);
          setMissingBills(emptyBill);
          dispatch(getGstTasks({ month: CurrentViewMonth?.month, year: CurrentViewMonth?.year }));
          onClose();
        }
      });
    }
  };

  const CopyToClipboardHandler = () => {
    navigator.clipboard.writeText(clipboardRef?.current.innerText);
    ShowMessage('success', `Copied to Clipboard Successfully`);
  };

  const ShareToWhatsappHandler = () => {
    window.open(`whatsapp://send?phone=${dataInput.client.glContactMobileNo}&text=${encodeURI(clipboardRef?.current.innerText)}`);
    ShowMessage('success', `Shared to Whatsapp Successfully`);
  };

  const items = [
    {
      title: 'Contact the Client',
      description: (
        <>
          {(selectedStatus === 0 || currentStatus === 0) && (
            <div className="d-flex flex-wrap">
              <div className="d-block w-100">
                {dataInput?.feedback?.map((item) => (
                  <div key={item?.reminderDate} className="feedback-card">
                    <div>
                      <p>{item?.notes}</p>
                      {item?.reminderDate ? <p className="reminder-content">{moment(item?.reminderDate).format('YYYY-MM-DD')}</p> : <></>}
                    </div>
                  </div>
                ))}
              </div>
              {currentStatus === 0 ?
                <>
                  <InputContainer
                    containerClassName="pe-3 pb-3 col-md-12"
                    label="Feedback"
                    type="textarea"
                    id="feedbackDescription"
                    name="feedbackDescription"
                    value={dataInput?.feedbackDescription}
                    error={formErrors?.feedbackDescription}
                    onChange={inputChangeHandler}
                    placeholder="Please Enter Feedback"
                    disabled={currentStatus !== 0 && !isSuperAdmin}
                  />
                  <div className="col-md-12">
                    <InputContainer
                      containerClassName="pe-3 pb-3 col-md-6"
                      label="Remainder Date"
                      format="dd/mm/yy"
                      type="prime-date"
                      picker="date"
                      id="remainderDate"
                      name="remainderDate"
                      value={dataInput?.remainderDate}
                      error={formErrors?.remainderDate}
                      onChange={inputChangeHandler}
                      placeholder="Please Choose Remainder Date"
                      disabled={currentStatus !== 0 && !isSuperAdmin}
                    />
                  </div>
                </>
                : <></>}
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Nil Return"
                type="toggle"
                checkedChildren="Yes"
                unCheckedChildren="No"
                id="nilReturn"
                name="nilReturn"
                value={dataInput?.nilReturn}
                onChange={(value) => toggleChangeHandler('nilReturn', value)}
                disabled={currentStatus !== 0 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Data Collected Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="dataCollectedDate"
                name="dataCollectedDate"
                value={dataInput?.dataCollectedDate}
                error={formErrors?.dataCollectedDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Data Collected Date"
                disabled={currentStatus !== 0 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Tally Entry',
      description: (
        <>
          {(selectedStatus === 1 || currentStatus === 1) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tally Entry Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="tallyEntryDate"
                name="tallyEntryDate"
                value={dataInput?.tallyEntryDate}
                error={formErrors?.tallyEntryDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Tally Entry Date"
                disabled={currentStatus !== 1 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-1 Filing',
      description: (
        <>
          {(selectedStatus === 2 || currentStatus === 2) && (
            <div className="d-flex flex-wrap pe-3 pb-3 ">
              <div className="col-12 m-2">
                <Tag className="f-14-px due-date-card">
                  Opening Date : <span className="fw-bold">{ordinal(dataInput?.gstr1OpeningDate)}</span>
                </Tag>
                <Tag className="f-14-px due-date-card">
                  Due Date : <span className="fw-bold">{ordinal(dataInput?.gstr1DueDate)}</span>
                </Tag>
              </div>
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Acknowledgement Number"
                type="text"
                id="acknowledgementNo"
                name="acknowledgementNo"
                value={dataInput?.acknowledgementNo}
                error={formErrors?.acknowledgementNo}
                onChange={inputChangeHandler}
                placeholder="Please Enter Acknowledgement Number"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="GSTR-1 Filing Date"
                format="dd/mm/yy"
                type="prime-date"
                picker="date"
                id="gstr1FilingDate"
                name="gstr1FilingDate"
                value={dataInput?.gstr1FilingDate}
                error={formErrors?.gstr1FilingDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose GSTR-1 Filing Date"
                disabled={currentStatus !== 2 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-2B Reconciliation',
      description: (
        <>
          {(selectedStatus === 3 || currentStatus === 3) && (
            <div className="mb-2">
              <div className="col-12 m-2">
                <Tag className="f-14-px due-date-card">
                  Opening Date : <span className="fw-bold">{ordinal(dataInput?.gstr2BOpeningDate)}</span>
                </Tag>
                <Tag className="f-14-px due-date-card">
                  Due Date : <span className="fw-bold">{ordinal(dataInput?.gstr2BDueDate)}</span>
                </Tag>
              </div>
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-12"
                inputClassName="d-flex"
                type="radio"
                id="gstr2bReconciliation"
                name="gstr2bReconciliation"
                value={dataInput?.gstr2bReconciliation}
                error={formErrors?.gstr2bReconciliation}
                onChange={inputChangeHandler}
                disabled={currentStatus !== 3 && !isSuperAdmin}
                options={reconciliationOptions}
              />
              {dataInput?.gstr2bReconciliation === "DataNotMatching" ?
                <>
                  <Tabs
                    defaultActiveKey="1"
                    items={[
                      {
                        key: '1',
                        label: 'Creditor not filed',
                        children: (
                          <Bills
                            bills={counterParyBills}
                            setBills={setCounterParyBills}
                            mode={mode}
                            recordType={recordType}
                            currentStatus={currentStatus}
                            billType="Creditor not filed"
                            isSuperAdmin={isSuperAdmin}
                          />
                        ),
                      },
                      {
                        key: '2',
                        label: 'Creditor filed, bill missing',
                        children: (
                          <Bills
                            bills={missingBills}
                            setBills={setMissingBills}
                            mode={mode}
                            recordType={recordType}
                            currentStatus={currentStatus}
                            billType="Creditor filed, bill missing"
                            isSuperAdmin={isSuperAdmin}
                          />
                        ),
                      },
                    ]}
                  />
                  <div className="pe-3 pb-3 w-100">
                    <div className="clipboard-section">
                      <div ref={clipboardRef}>
                        <p>
                          Hi {dataInput?.client?.clientName?.toUpperCase()}, <br />
                          &emsp;&emsp;The following purchase bills are not filed by the creditor:<br />
                          {counterParyBills.length > 0 ?
                            <>
                              {counterParyBills.map((cpBill) => (
                                <>&emsp;&emsp;{cpBill?.billNo}&emsp;-&emsp;{cpBill?.billDate ? moment(cpBill?.billDate).format("DD/MM/YYYY") : ""}&emsp;-&emsp;{cpBill?.gstIn}&emsp;-&emsp;{cpBill?.partyName}&emsp;-&emsp;{cpBill?.billValue}<br /></>
                              ))}
                              <br />
                            </>
                            : <></>}
                          {missingBills.length > 0 ?
                            <>
                              &emsp;&emsp;The following purchase bills are filed by the creditor, and the bills are
                              missing:<br />

                              {missingBills.map((mBill) => (
                                <>&emsp;&emsp;{mBill?.billNo}&emsp;-&emsp;{mBill?.billDate ? moment(mBill?.billDate).format("DD/MM/YYYY") : ""}&emsp;-&emsp;{mBill?.gstIn}&emsp;-&emsp;{mBill?.partyName}&emsp;-&emsp;{mBill?.billValue}<br /></>
                              ))}
                            </>
                            : <></>}
                          <br />
                          &emsp;&emsp;Kindly, clarify the above mentioned bills shortly.<br />
                          <br />- Gems Clip Audit Services
                        </p>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className="clipboard-icon-watsapp"
                          onClick={ShareToWhatsappHandler}
                        />
                        <FontAwesomeIcon icon={faCopy} className="clipboard-icon-copy" onClick={CopyToClipboardHandler} />
                      </div>
                    </div>
                  </div>
                </> : <></>
              }

            </div>
          )}

        </>

      ),
    },
    {
      title: 'GSTR-3B Upload',
      description: (
        <>
          {(selectedStatus === 4 || currentStatus === 4) && (
            <div className="d-flex flex-wrap">
              <div className="col-12 m-2">
                <Tag className="f-14-px due-date-card">
                  Opening Date : <span className="fw-bold">{ordinal(dataInput?.gstr3BOpeningDate)}</span>
                </Tag>
                <Tag className="f-14-px due-date-card">
                  Due Date : <span className="fw-bold">{ordinal(dataInput?.gstr3BDueDate)}</span>
                </Tag>
              </div>
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="GST Payable"
                type="number"
                id="gstPayable"
                name="gstPayable"
                value={dataInput?.gstPayable}
                error={formErrors?.gstPayable}
                onChange={inputChangeHandler}
                placeholder="Please Enter GST Payable"
                disabled={currentStatus !== 4 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Inform the Payment',
      description: (
        <>
          {(selectedStatus === 5 || currentStatus === 5) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tax Value"
                type="text"
                id="taxValue"
                name="taxValue"
                value={dataInput?.gstPayable}
                error={formErrors?.gstPayable}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Value"
                disabled
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Fee"
                type="number"
                id="fee"
                name="fee"
                value={dataInput?.fee}
                error={formErrors?.fee}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee"
                disabled={currentStatus !== 5 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Penalty"
                type="number"
                id="penalty"
                name="penalty"
                value={dataInput?.penalty}
                onChange={inputChangeHandler}
                placeholder="Please Enter Penalty"
                disabled={currentStatus !== 5 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Interest"
                type="number"
                id="interest"
                name="interest"
                value={dataInput?.interest}
                onChange={inputChangeHandler}
                placeholder="Please Enter Interest"
                disabled={currentStatus !== 5 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Total GST Payment"
                type="number"
                id="totalGstPayment"
                name="totalGstPayment"
                value={
                  (Number(dataInput?.gstPayable) || 0) +
                  (Number(dataInput?.penalty) || 0) +
                  (Number(dataInput?.interest) || 0) +
                  (Number(dataInput?.fee) || 0)
                }
                // error={formErrors?.totalGstPayment}
                onChange={inputChangeHandler}
                placeholder="Please Enter Total GST Payment"
                disabled={currentStatus !== 5 && !isSuperAdmin}
              />
              <div className="pe-3 pb-3 w-100">
                <div className="clipboard-section">
                  <div ref={clipboardRef}>
                    <p>
                      Hi {dataInput?.client?.clientName?.toUpperCase()}, <br />
                      &emsp;&emsp;Your GST return for the month {taskMonth} <b>in under process</b>.
                      <br />
                      &emsp;&emsp;GST Payable is Rs. {dataInput?.gstPayable}
                      <br />
                      &emsp;&emsp;Fee for GST filing is Rs. {dataInput?.fee}
                      <br />
                      {dataInput?.penalty && (
                        <>
                          &emsp;&emsp;Penalty Rs. {dataInput.penalty}
                          <br />
                        </>
                      )}
                      {dataInput?.interest && (
                        <>
                          &emsp;&emsp;Interest Rs. {dataInput.interest}
                          <br />
                        </>
                      )}
                      &emsp;&emsp;Total amount is Rs.{' '}
                      {(Number(dataInput?.gstPayable) || 0) +
                        (Number(dataInput?.penalty) || 0) +
                        (Number(dataInput?.interest) || 0) +
                        (Number(dataInput?.fee) || 0)}
                      <br />
                      &emsp;&emsp;Kindly, make the payment shortly to avoid the penality charges.
                      <br />
                      <br />- Gems Clip Audit Services
                    </p>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="clipboard-icon-watsapp"
                      onClick={ShareToWhatsappHandler}
                    />
                    <FontAwesomeIcon icon={faCopy} className="clipboard-icon-copy" onClick={CopyToClipboardHandler} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Collection of the Payment',
      description: (
        <>
          {(selectedStatus === 6 || currentStatus === 6) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Tax Collected"
                type="number"
                id="taxCollected"
                name="taxCollected"
                value={dataInput?.taxCollected}
                error={formErrors?.taxCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Tax Collected"
                disabled={currentStatus !== 6 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Fee Collected"
                type="number"
                id="feeCollected"
                name="feeCollected"
                value={dataInput?.feeCollected}
                error={formErrors?.feeCollected}
                onChange={inputChangeHandler}
                placeholder="Please Enter Fee Collected"
                disabled={currentStatus !== 6 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Collection Date"
                format="dd/mm/yy"
                picker="date"
                type="prime-date"
                id="collectionDate"
                name="collectionDate"
                value={dataInput?.collectionDate}
                error={formErrors?.collectionDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Collection Date"
                disabled={currentStatus !== 6 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Mode of Payment"
                type="select"
                id="modeOfPayment"
                name="modeOfPayment"
                value={dataInput?.modeOfPayment}
                error={formErrors?.modeOfPayment}
                onChange={selectChangeHandler.bind(this, 'modeOfPayment', 'value')}
                options={modeOfPayment}
                optionsValueKey="value"
                optionsLabelKey="label"
                disabled={currentStatus !== 6 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'GSTR-3B Filing',
      description: (
        <>
          {(selectedStatus === 7 || currentStatus === 7) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Acknowledgement Number"
                type="text"
                id="gstr3AcknowledgementNo"
                name="gstr3AcknowledgementNo"
                value={dataInput?.gstr3AcknowledgementNo}
                error={formErrors?.gstr3AcknowledgementNo}
                onChange={inputChangeHandler}
                placeholder="Please Enter Acknowledgement Number"
                disabled={currentStatus !== 7 && !isSuperAdmin}
              />
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Filed Date"
                type="prime-date"
                format="dd/mm/yy"
                picker="date"
                id="gstr3FiledDate"
                name="gstr3FiledDate"
                value={dataInput?.gstr3FiledDate}
                error={formErrors?.gstr3FiledDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Filed Date"
                disabled={currentStatus !== 7 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Challan Download',
      description: (
        <>
          {(selectedStatus === 8 || currentStatus === 8) && (
            <div className="d-flex flex-wrap">
              <InputContainer
                containerClassName="pe-3 pb-3 col-md-6"
                label="Download Date"
                type="prime-date"
                format="dd/mm/yy"
                picker="date"
                id="downloadDate"
                name="downloadDate"
                value={dataInput?.downloadDate}
                error={formErrors?.downloadDate}
                onChange={inputChangeHandler}
                placeholder="Please Choose Download Date"
                disabled={currentStatus !== 8 && !isSuperAdmin}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <Drawer
      title={recordType === 'new' ? 'Create a New GST Task' : 'Update GST Task'}
      width={selectedStatus === 3 || currentStatus === 3 ? '85%' : '40%'}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <DrawerButtons
          showSave={currentStatus < 9 || isSuperAdmin}
          showProceed={currentStatus < 9}
          onClose={onClose}
          onSubmitHandler={onSubmitHandler}
        />
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <div className="mb-4 w-100">
            <ClientCard data={dataInput.client} pageName="GST Tasks">
              <div className="text-right">
                <p className='custom-client'>{taskMonth || ''}</p>
              </div>
            </ClientCard>
          </div>
          <Steps current={currentStatus} onChange={handleOnChange} direction="vertical" percent={50} items={items} />
        </Row>
      </Form>
    </Drawer>
  );
};
export default DrawerGstTask;
