import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

//
import AccountPopover from './AccountPopover';
import { updateFinancialYear, updateViewMonth } from '../../../STORE/Slice/Utils';
import { UseViewMonth, UseViewFinancialYear, UseHeaderCalendar, UseHeaderFinancialYear } from '../../../STORE/Selector/Utils';

// utils
import { bgBlur } from '../../../utils/cssStyles';
import InputContainer from '../../../components/InputContainer/InputContainer';

// ----------------------------------------------------------------------

const NAV_WIDTH = 180;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Header() {
  const [viewMonth, setViewMonth] = useState(null);
  const [viewFinancialYear, setViewFinancialYear] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  // Custom Selector Hooks
  const CurrentViewMonth = UseViewMonth();
  const CurrentViewFinancialYear = UseViewFinancialYear();
  const ViewCalendar = UseHeaderCalendar();
  const ViewFinancialYear = UseHeaderFinancialYear();

  useEffect(() => {
    if (CurrentViewMonth?.month && CurrentViewMonth?.year) {
      setViewMonth(moment(`${CurrentViewMonth?.month}-${CurrentViewMonth?.year}`, "MM-YYYY"))
    }
  }, [CurrentViewMonth?.month, CurrentViewMonth?.year])

  useEffect(() => {
    if (CurrentViewFinancialYear) {
      setViewFinancialYear(CurrentViewFinancialYear)
    }
  }, [CurrentViewFinancialYear])

  const MonthNavigateHandler = (navigate) => {
    let month = null;
    if (navigate === 1) {
      month = moment(viewMonth, "MM-YYYY").add(1, 'months').format("MMM-YYYY");
    } else {
      month = moment(viewMonth, "MM-YYYY").subtract(1, 'months').format("MMM-YYYY");
    }

    dispatch(updateViewMonth(month));
  };

  const YearNavigateHandler = (value) => {
    const currentViewFinancialYearIndex = financialYearOptions.findIndex((item) => item.value === CurrentViewFinancialYear)
    if (value === -1 && financialYearOptions[currentViewFinancialYearIndex - 1]?.value) {
      dispatch(updateFinancialYear(financialYearOptions[currentViewFinancialYearIndex - 1]?.value));
    }

    if (value === 1 && financialYearOptions[currentViewFinancialYearIndex + 1]?.value) {
      dispatch(updateFinancialYear(financialYearOptions[currentViewFinancialYearIndex + 1]?.value));
    }
  };

  const MonthChangeHandler = (event) => {
    if (event.target.value) {
      dispatch(updateViewMonth(event.target.value));
    }
  };

  const handleSelectChangeHandler = (valueField, valueObject) => {
    dispatch(updateFinancialYear(valueObject[valueField]));
  };

  return (
    <StyledRoot>
      <StyledToolbar>
        {ViewCalendar.includes(location?.pathname.replace("/", "")) ?
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faAnglesLeft} className='month-navigator' onClick={() => MonthNavigateHandler(-1)} />
            <InputContainer
              type="prime-date"
              id="overallmonth"
              format="M-yy"
              value={new Date(viewMonth)}
              name="overallmonth"
              view="month"
              onChange={MonthChangeHandler}
              placeholder="Enter Month"
              containerClassName="pe-3 pb-1 w-170-px"
              readOnlyInput={false}
            />
            <FontAwesomeIcon icon={faAnglesRight} className='month-navigator' onClick={() => MonthNavigateHandler(1)} />
          </div>
          : null}
        {ViewFinancialYear.includes(location?.pathname.replace("/", "")) ?
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon icon={faAnglesLeft} className='month-navigator' onClick={() => YearNavigateHandler(-1)} />
            <InputContainer
              containerClassName="pe-3 pb-1 w-170-px"
              type="select"
              id="viewFinancialYear"
              name="viewFinancialYear"
              value={viewFinancialYear}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={handleSelectChangeHandler.bind(this, 'value')}
              options={financialYearOptions}
              optionsValueKey="value"
              optionsLabelKey="label"
              placeholder="Financial Year"
            />
            <FontAwesomeIcon icon={faAnglesRight} className='month-navigator' onClick={() => YearNavigateHandler(1)} />
          </div>
          : null}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}


const financialYearOptions = [
  { label: "2001-2002", value: "2001-2002" },
  { label: "2002-2003", value: "2002-2003" },
  { label: "2003-2004", value: "2003-2004" },
  { label: "2004-2005", value: "2004-2005" },
  { label: "2005-2006", value: "2005-2006" },
  { label: "2006-2007", value: "2006-2007" },
  { label: "2007-2008", value: "2007-2008" },
  { label: "2008-2009", value: "2008-2009" },
  { label: "2009-2010", value: "2009-2010" },
  { label: "2010-2011", value: "2010-2011" },
  { label: "2011-2012", value: "2011-2012" },
  { label: "2012-2013", value: "2012-2013" },
  { label: "2013-2014", value: "2013-2014" },
  { label: "2014-2015", value: "2014-2015" },
  { label: "2015-2016", value: "2015-2016" },
  { label: "2016-2017", value: "2016-2017" },
  { label: "2017-2018", value: "2017-2018" },
  { label: "2018-2019", value: "2018-2019" },
  { label: "2019-2020", value: "2019-2020" },
  { label: "2020-2021", value: "2020-2021" },
  { label: "2021-2022", value: "2021-2022" },
  { label: "2022-2023", value: "2022-2023" },
  { label: "2023-2024", value: "2023-2024" },
  { label: "2024-2025", value: "2024-2025" },
  { label: "2025-2026", value: "2025-2026" },
  { label: "2026-2027", value: "2026-2027" },
  { label: "2027-2028", value: "2027-2028" },
  { label: "2028-2029", value: "2028-2029" },
  { label: "2029-2030", value: "2029-2030" },
  { label: "2030-2031", value: "2030-2031" },
  { label: "2031-2032", value: "2031-2032" },
  { label: "2032-2033", value: "2032-2033" },
  { label: "2033-2034", value: "2033-2034" },
  { label: "2034-2035", value: "2034-2035" },
  { label: "2035-2036", value: "2035-2036" },
  { label: "2036-2037", value: "2036-2037" },
  { label: "2037-2038", value: "2037-2038" },
  { label: "2038-2039", value: "2038-2039" },
  { label: "2039-2040", value: "2039-2040" },
  { label: "2040-2041", value: "2040-2041" },
  { label: "2041-2042", value: "2041-2042" },
  { label: "2042-2043", value: "2042-2043" },
  { label: "2043-2044", value: "2043-2044" },
  { label: "2044-2045", value: "2044-2045" },
  { label: "2045-2046", value: "2045-2046" },
  { label: "2046-2047", value: "2046-2047" },
  { label: "2047-2048", value: "2047-2048" },
  { label: "2048-2049", value: "2048-2049" },
  { label: "2049-2050", value: "2049-2050" },
  { label: "2050-2051", value: "2050-2051" },
  { label: "2051-2052", value: "2051-2052" },
  { label: "2052-2053", value: "2052-2053" },
  { label: "2053-2054", value: "2053-2054" },
  { label: "2054-2055", value: "2054-2055" },
  { label: "2055-2056", value: "2055-2056" },
  { label: "2056-2057", value: "2056-2057" },
  { label: "2057-2058", value: "2057-2058" },
  { label: "2058-2059", value: "2058-2059" },
  { label: "2059-2060", value: "2059-2060" },
  { label: "2060-2061", value: "2060-2061" },
  { label: "2061-2062", value: "2061-2062" },
  { label: "2062-2063", value: "2062-2063" },
  { label: "2063-2064", value: "2063-2064" },
  { label: "2064-2065", value: "2064-2065" },
  { label: "2065-2066", value: "2065-2066" },
  { label: "2066-2067", value: "2066-2067" },
  { label: "2067-2068", value: "2067-2068" },
  { label: "2068-2069", value: "2068-2069" },
  { label: "2069-2070", value: "2069-2070" },
  { label: "2070-2071", value: "2070-2071" },
  { label: "2071-2072", value: "2071-2072" },
  { label: "2072-2073", value: "2072-2073" },
  { label: "2073-2074", value: "2073-2074" },
  { label: "2074-2075", value: "2074-2075" },
  { label: "2075-2076", value: "2075-2076" },
  { label: "2076-2077", value: "2076-2077" },
  { label: "2077-2078", value: "2077-2078" },
  { label: "2078-2079", value: "2078-2079" },
  { label: "2079-2080", value: "2079-2080" },
];
