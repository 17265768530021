/* eslint-disable react/prop-types */
import { Button } from 'antd';
import InputContainer from '../../components/InputContainer/InputContainer';

const BankDetails = ({ statement, setStatement, isSuperAdmin, mode, userType, currentStatus, typeOptions }) => {

  const dateChangeHandler = (index, e) => {
    const { name, value } = e.target;

    const existingStatement = [...statement];

    existingStatement[index][name] = value;
    setStatement([...existingStatement]);
  };

  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const existingStatement = [...statement];

    existingStatement[index][name] = value;
    setStatement([...existingStatement]);
  };
  const newStatementHandler = () => {
    const newStatement = {
      bank: '',
      ifsc: '',
      type: '',
      accountNo: '',
      fromDate: '',
      toDate: '',
      status: '',
    };
    setStatement([...statement, newStatement]);
  };

  const deleteStatementHandler = (index) => {
    setStatement([...statement.toSpliced(index, 1)]);
  };

  const selectChangeHandler = (valueObject, index) => {
    const updatedStatement = [...statement];
    updatedStatement[index] = { ...updatedStatement[index], type: valueObject.value };
    setStatement(updatedStatement);
  };

  const toggleChangeHandler = (index, value) => {
    const updatedStatement = [...statement];
    updatedStatement[index] = { ...updatedStatement[index], status: value };
    setStatement(updatedStatement);
  };

  return (
    <>
      {statement?.map((item, index) => (
        <>
          <div className="d-flex flex-wrap" key={index}>
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-4"
              label="Bank"
              type="text"
              id={`bank-${index + 1}`}
              name="bank"
              value={item.bank}
              onChange={(e) => inputChangeHandler(e, index)}
              placeholder="Please Enter Bank Name"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName=" pe-3 pb-3 col-md-4"
              label="Branch or IFSC"
              type="text"
              id={`ifsc-${index + 1}`}
              name="ifsc"
              value={item.ifsc}
              onChange={(e) => inputChangeHandler(e, index)}
              placeholder="Please Enter IFSC Code"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-2"
              label="Type"
              type="select"
              id={`type-${index + 1}`}
              name="type"
              value={item.type}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e) => selectChangeHandler(e, index)}
              options={typeOptions}
              optionsValueKey="value"
              optionsLabelKey="label"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-2"
              label="Account Number"
              type="number"
              id={`accountNo-${index + 1}`}
              name="accountNo"
              value={item.accountNo}
              onChange={(e) => inputChangeHandler(e, index)}
              placeholder="Please Enter Account Number"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-2"
              label="From Date"
              format="dd/mm/yy"
              type="prime-date"
              picker="date"
              id={`fromDate-${index + 1}`}
              name="fromDate"
              value={item?.fromDate}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={dateChangeHandler.bind('this', index)}
              placeholder="Please Choose From Date"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-2"
              label="To Date"
              format="dd/mm/yy"
              type="prime-date"
              picker="date"
              id={`toDate-${index + 1}`}
              name="toDate"
              value={item.toDate}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={dateChangeHandler.bind('this', index)}
              placeholder="Please Choose To Date"
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            <InputContainer
              containerClassName="pe-3 pb-3 col-md-2"
              label="Status"
              type="toggle"
              checkedChildren="Active"
              unCheckedChildren="In Active"
              id={`status-${index + 1}`}
              name="status"
              value={item.status}
              onChange={(value) => toggleChangeHandler(index, value)}
              disabled={currentStatus !== 0 && !isSuperAdmin}
            />
            {currentStatus === 0 || isSuperAdmin ? (
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                <Button type="primary" danger className="mt-2" onClick={() => deleteStatementHandler(index)}>
                  Remove
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <hr />
        </>
      ))}
      {currentStatus === 0 || isSuperAdmin ? (
        <Button type="primary" className="mb-4" onClick={newStatementHandler}>
          Add New Statement
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default BankDetails;
