import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Tabs } from 'antd';

import BillingSummary from '../sections/billing/Sections/BillingSummary';
import BillingDashboard from '../sections/billing/Sections/BillingDashboard';
import TaxDashboard from '../sections/billing/Sections/TaxDashboard';
// CSS
import '../css/Billings.scss';

const Billing = () => {
  const [activeTab, setActiveTab] = useState("1");

  const onTabChange = (activeKey) => {
    setActiveTab(activeKey)
  }

  const items = [
    {
      key: '1',
      label: 'Billing',
      children: (
        <BillingSummary activeTab={activeTab} />
      ),
    },
    {
      key: '2',
      label: 'Billing Dashboard',
      children: (
        <BillingDashboard activeTab={activeTab} />
      ),
    },
    {
      key: '3',
      label: 'Tax Dashboard',
      children: (
        <TaxDashboard activeTab={activeTab} />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Client's | Gems Clip </title>
      </Helmet>
      <div className="row mt-4 due-date-wrapper">
        <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
      </div>

    </>
  );
};

export default Billing;
