import { createAsyncThunk } from '@reduxjs/toolkit'

// Axios
import axios from "../../axios/index";
import urls from "../../axios/url";

// Get All Pans
export const getPans = createAsyncThunk('pans/getPans', async () => {
    try {
        const response = await axios.get(`${urls.panUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Get All Pan
export const getPan = createAsyncThunk('pans/getPan', async () => {
    try {
        const response = await axios.get(`${urls.panUrl.url}`)
        return response.data
    } catch (err) {
        return err
    }
})

// Create Pan
export const createPan = createAsyncThunk('pans/createPan', async (arg) => {
    try {
        const response = await axios.post(`${urls.panUrl.url}`, arg);
        return response.data
    } catch (err) {
        return err
    }
})

// Update Pan
export const updatePan = createAsyncThunk('pans/updatePan', async (arg) => {
    try {
        const response = await axios.patch(`${urls.panUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})

// Delete Pan
export const deletePan = createAsyncThunk('pans/deletePan', async (arg) => {
    try {
        const response = await axios.delete(`${urls.panUrl.url}/${arg._id}`, arg)
        return response.data
    } catch (err) {
        return err
    }
})