import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


import { useNavigate } from "react-router-dom";
import { authLogout } from "../../../STORE/Slice/Auth";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const [user, setUser] = useState({
    name: "",
    email: "",
    role: ""
  })
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.token) {
      setUser({
        name: auth.user.name,
        email: auth.user.email,
        userName: auth.user.userName
      })
    }
  }, [])

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };


  const goToDashboard = () => {
    navigate("/dashboard");
    setOpen(null);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    dispatch(authLogout());
    localStorage.removeItem("userInfo");
    setOpen(null);
    navigate("/login");
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={auth?.user?.photo ? `${process.env.REACT_APP_BACKEND_BASE_URL}api/download/${auth?.user?.photo}` : "/assets/images/avatars/avatar_default.png"} alt="photoURL">
          <Avatar src="/assets/images/avatars/avatar_default.png" />
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.userName}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={goToDashboard}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
