import React, { useState, useEffect } from 'react';
import { Button, Drawer, Space } from 'antd';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { Confirmation, ShowMessage } from '../../components/Utils';
import BillingDetails from './Sections/BillingDetails';
import { getClientsAutoComplete } from '../../STORE/MiddleWare/AutoComplete';
import { createBilling, deleteBilling, getBillings, updateBilling } from '../../STORE/MiddleWare/Billing';
import { UseAutoCompleteClients } from '../../STORE/Selector/AutoComplete';

// eslint-disable-next-line react/prop-types
const DrawerBilling = ({ show, setShow, drawerType, clientType, rowValue }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('view');
  const [clients, setClients] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const clientsData = UseAutoCompleteClients();

  useEffect(() => {
    setClients(clientsData);
  }, [clientsData]);

  const initData = {
    date: new Date(),
    period: '',
    clientId: '',
    taskId: '',
    task: '',
    particulars: '',
    modeOfPayment: '',
    type: '',
    amount: '',
    remarks: '',
    invoiceNumber: '',
    receiptNumber: '',
  };

  useEffect(() => {
    setOpen(show);
    setFormErrors({});
    setMode(clientType === 'new' ? 'edit' : 'view');
    if (rowValue?._id) {
      setData({ ...rowValue });
    } else {
      setData(initData);
    }

    if (show) {
      dispatch(getClientsAutoComplete());
    }
  }, [show, rowValue, drawerType]);

  const onClose = () => {
    setOpen(false);
    setShow(false);
    setMode('view');
  };

  const onDelete = () => {
    Confirmation('Are you sure, you want to delete this Billing?', '', '', ['No', 'YES'], true).then((isConfirm) => {
      if (isConfirm) {
        const billing = { _id: data._id };
        dispatch(deleteBilling(billing)).then((res) => {
          if (res?.payload?.message === 'Data Deleted Successfully') {
            ShowMessage('success', 'Billing Deleted Successfully');
            dispatch(getBillings());
            onClose();
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value) {
      setFormErrors({ ...formErrors, [name]: '' });
    }

    setData({ ...data, [name]: value });
  };

  const handleSelectChangeHandler = (nameField, valueField, valueObject) => {

    if (valueObject[valueField]) {
      setFormErrors({ ...formErrors, [nameField]: '' });
    }
    let newValue = valueObject[valueField];

    if (newValue === 'Bill Raised') {
      newValue = 'Debit';
    } else if (newValue === 'Bill Collected') {
      newValue = 'Credit';
    }

    setData({ ...data, [nameField]: newValue });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.period) {
      errors.period = 'Please Enter the Period';
    }

    if (!values.clientId) {
      errors.clientId = 'Please Enter Client Id';
    }
    if (!values.task) {
      errors.task = 'Please Enter Task';
    }
    if (!values.particulars) {
      errors.particulars = 'Please Enter Particulars';
    }
    if (!values.type) {
      errors.type = 'Please Select Bill Type';
    }
    if (!values.type) {
      errors.type = 'Please Select Bill Type';
    }
    if (!values.invoiceNumber) {
      errors.invoiceNumber = 'Please Enter Invoice Number';
    }
    if (!values.modeOfPayment && values?.type === 'Credit') {
      errors.modeOfPayment = 'Please Enter Mode Of Payment';
    }
    if (!values.receiptNumber && values?.type === 'Credit') {
      errors.receiptNumber = 'Please Enter Receipt Number';
    }
    return errors;
  };

  const onSubmit = () => {
    const fieldErrors = validate(data);
    if (!_.isEmpty(fieldErrors)) {
      setFormErrors(fieldErrors);
      return;
    }
    const billing = {
      date: data.date ? moment(data.date).format('YYYY-MM-DD') : null,
      period: data.period,
      clientId: data.clientId,
      taskId: data.taskId,
      task: data.task,
      type: data.type,
      amount: data.amount,
      particulars: data.particulars,
      modeOfPayment: data?.type === 'Credit' ? data.modeOfPayment : null,
      remarks: data?.remarks,
      invoiceNumber: data?.invoiceNumber,
      receiptNumber: data?.type === 'Credit' ? data?.receiptNumber : null,
    };

    if (clientType === 'existing') {
      billing._id = data._id;
      dispatch(updateBilling(billing)).then((res) => {
        if (res?.payload?.message === 'Data Updated Successfully') {
          ShowMessage('success', 'Billing Updated Successfully');
          dispatch(getBillings());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    } else {
      dispatch(createBilling(billing)).then((res) => {
        if (res?.payload?.message === 'Data Created Successfully') {
          ShowMessage('success', 'Billing Created Successfully');
          dispatch(getBillings());
          onClose();
        } else {
          ShowMessage('error', res?.payload?.response?.data?.validation?.body?.message || 'Something went wrong');
        }
      });
    }
  };

  return (
    <Drawer
      title={drawerType === 'existing' ? 'Update Billing' : 'Create a New Billing'}
      width={720}
      onClose={onClose}
      open={open}
      style={{ body: { paddingBottom: 80 } }}
      extra={
        <Space>
          {mode === 'view' ? (
            <Button type="primary" onClick={() => setMode('edit')}>
              Edit
            </Button>
          ) : (
            <></>
          )}
          {mode === 'edit' ? (
            <>
              {/* {clientType === 'existing' ? (
                <Button type="primary" danger onClick={onDelete}>
                  Delete
                </Button>
              ) : (
                <></>
              )} */}
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSubmit} type="primary">
                Submit
              </Button>
            </>
          ) : (
            <></>
          )}
        </Space>
      }
    >
      <div>
        <BillingDetails
          mode={mode}
          clientType={clientType}
          data={data}
          clients={clients}
          formErrors={formErrors}
          handleChange={handleChange}
          handleSelectChangeHandler={handleSelectChangeHandler}
        />
      </div>
    </Drawer>
  );
};
export default DrawerBilling;
