import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

// Component
import SearchTable from '../components/Table/SearchTable';
import { frameFilterArray } from '../components/Utils';

import DrawerItTask from '../sections/itTask/DrawerItTask';
import DrawerClients from '../sections/clients/DrawerClients';
import DrawerItLog from '../sections/log/DrawerItLog';

// Utils
import { UseItTasks } from '../STORE/Selector/ItTask';
import { getItTasks } from '../STORE/MiddleWare/ItTask';
import { UseViewFinancialYear } from '../STORE/Selector/Utils';

// CSS
import '../css/Clients.scss';

const ItTask = () => {
  const dispatch = useDispatch();

  // State
  const [show, setShow] = useState(false);
  const [userType, setUserType] = useState('new');
  const [clientsShow, setClientsShow] = useState(false);
  const [logsShow, setLogsShow] = useState(false);
  const [rowValue, setRowValue] = useState({});
  const [logValue, setLogValue] = useState(null);
  const [itTaskData, setItTaskData] = useState([]);

  // Custom Selector Hooks
  const itTasks = UseItTasks();
  const CurrentFinancialYear = UseViewFinancialYear();

  useEffect(() => {
    if (CurrentFinancialYear) {
      dispatch(getItTasks({ financialYear: CurrentFinancialYear }));
    }
  }, [CurrentFinancialYear, dispatch]);

  useEffect(() => {
    setItTaskData(itTasks?.ItTask);
  }, [itTasks]);

  const onRowClickHandler = (e, value) => {
    setShow(true);
    setUserType('existing');
    setRowValue(value);
  };

  const onClientClickHandler = (id) => {
    setShow(false);
    setClientsShow(true);
    setLogsShow(false);
    setRowValue({
      _id: id,
    });
  };

  const OpenChangelogHandler = (id) => {
    setShow(false);
    setLogsShow(true);
    setClientsShow(false);
    setLogValue(id)
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 's.no',
      align: 'center',
      width: '30px',
      onCell: () => ({ className: '' }),
      render: (value, row, index) => (row.page - 1) * 10 + (index + 1),
      fixed: 'left',
    },
    {
      title: 'Client ID',
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '50px',
      onCell: () => ({ className: ' ' }),
      render(text, row) {
        return <div>{row?.client?.clientId}</div>;
      },
      fixed: 'left',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      align: 'center',
      width: '150px',
      onCell: () => ({
        onClick: event => { event.stopPropagation(); },
        className: 'ellipsis-1'
      }),
      render(text, row) {
        return (
          <Button
            type="link"
            className="client-info-button client-info"
            onClick={() => onClientClickHandler(row.clientId)}
          >
            <p className="client-info">{text}</p>
          </Button>
        );
      },
      fixed: 'left',
      filters: frameFilterArray(itTaskData, 'clientName'),
      onFilter: (value, record) => record.clientName === value,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: 'Client Type',
      dataIndex: 'clientType',
      key: 'clientType',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: ' ' }),
      render(text, row) {
        return <div>{row?.client?.clientType}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'step',
      key: 'step',
      align: 'center',
      width: '130px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{steps[text]}</div>;
      },
      filters: stepsFilter,
      onFilter: (value, record) => record.step === value,
      sorter: (a, b) => a.step - b.step,
    },
    {
      title: 'Acknowledgement No',
      dataIndex: 'acknowledgementNumber',
      key: 'acknowledgementNumber',
      align: 'center',
      width: '100px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Mode of Payment',
      dataIndex: 'modeOfPayment',
      key: 'modeOfPayment',
      align: 'center',
      width: '70px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Total IT Payment',
      dataIndex: 'totalItPayment',
      key: 'totalItPayment',
      align: 'center',
      width: '80px',
      onCell: () => ({ className: ' ' }),
      render(text) {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Log',
      dataIndex: '_id',
      align: 'center',
      width: '40px',
      onCell: () => ({
        onClick: event => { event.stopPropagation(); },
      }),
      render(text) {
        return (
          <Button
            type="link"
            className="client-info-button client-info changelog"
            onClick={() => OpenChangelogHandler(text)}
          >
            <FontAwesomeIcon icon={faClockRotateLeft} className="changelog" />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> ItTask's | Gems Clip </title>
      </Helmet>

      <div className="row mt-4 client-wrapper">
        <SearchTable
          columns={columns}
          data={itTaskData}
          searchItems={['clientName']}
          onRowClickHandler={onRowClickHandler}
        />
      </div>
      <DrawerItTask show={show} setShow={setShow} userType={userType} rowValue={rowValue} setRowValue={setRowValue} />
      <DrawerClients show={clientsShow} setShow={setClientsShow} clientType={'readonly'} rowValue={rowValue} id={rowValue.clientId} />
      <DrawerItLog show={logsShow} setShow={setLogsShow} id={logValue} />
    </>
  );
};

export default ItTask;

const steps = [
  'Contact the Client',
  'Statement Preparation',
  'Inform the Payment',
  'Collection of the Payment',
  'Tax Payment',
  'Income Tax Filling',
  'E-verification',
  'Print',
  'Distribution',
  'Refund Follow Up',
  'Task Completed',
];

const stepsFilter = [
  { text: 'Contact the Client', value: 0 },
  { text: 'Statement Preparation', value: 1 },
  { text: 'Inform the Payment', value: 2 },
  { text: 'Collection of the Payment', value: 3 },
  { text: 'Tax Payment', value: 4 },
  { text: 'Income Tax Filling', value: 5 },
  { text: 'E-verification', value: 6 },
  { text: 'Print', value: 7 },
  { text: 'Distribution', value: 8 },
  { text: 'Refund Follow Up', value: 9 },
  { text: 'Task Completed', value: 10 },
];
